import DrawerCustom from '../../../components/drawer';
import { Box, Button, DialogActions, DialogContent, Paper } from '@mui/material';
import FormTask from './FormTask';

interface Props {
    handleClose: () => void;
    isOpen: boolean;
    idDetail?: number;
    callBack: () => void;
}


const ModalTask = ({ handleClose, isOpen, idDetail, callBack }: Props) => {


    return (
        <Box>
            <DrawerCustom title={idDetail ? "Edit Task" : "Create Task"} handleClose={handleClose} openDrawer={isOpen}>
                <DialogContent sx={{ background: "#efecec", px: 2 }}>
                    <Paper sx={{ p: 2, pt: 3, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
                        <FormTask idDetail={idDetail} handleClose={handleClose} callBack={callBack} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' type={"submit"} form='form-handle-task' >Submit</Button>
                </DialogActions>
            </DrawerCustom>


        </Box>

    )
}

export default ModalTask
export default {
    dataUser: [
        { id: "1", label: "Jubin Chawla" },
        { id: "2", label: "Mark Johnson" },
        { id: "3", label: "Shane Lee" },
        { id: "4", label: "Chris Crains" },
        { id: "5", label: "Jonathan Trott" },
        { id: "6", label: "Rohit Sharma" },
        { id: "7", label: "Mark Lee" },
        { id: "8", label: "Sain Williams" },
        { id: "9", label: "Parth Aulins" },
        { id: "10", label: "Rahul Bose" },
    ],
    priority: ['HIGH', 'MEDIUM', 'LOW'],
    statusTask: ['PENDING', 'INPROGRESS'],
    linkToFeature: ['CRM', 'Reservation', 'Products'],
    reservation: ['Booking', 'Quotation'],
    crm: ['Contact', 'Company'],
    product: ['Tour', 'Golf'],
    titles: [
        {label: "Mr", value: "Mr"},
        {label: "Mrs", value: "Mrs"},
        {label: "Ms", value: "Ms"},
        {label: "Mstr", value: "Mstr"},
        {label: "Miss", value: "Miss"},
        {label: "Dr", value: "Dr"},
        {label: "Prof", value: "Prof"},
        {label: "Sir", value: "Sir"},
        {label: "Lady", value: "Lady"},
        {label: "Other", value: "Other"},
        // { label: "Infant", value: "Infant" },
    ],
    dataKanban: [
        {
            id: "1",
            name: "Overdue",
            color: "#ff5752",
            data: [
                // {
                //     id: 1,
                //     priority: "high",
                //     title: "This is title",
                //     deadline: "2024-03-05"
                // },
                // {
                //     id: 2,
                //     priority: "medium",
                //     title: "This is title",
                //     deadline: "2024-03-05"
                    
                // },
                // {
                //     id: 3,
                //     priority: "low",
                //     title: "This is title",
                //     deadline: "2024-03-07"
                // },
            ]
        },
        {
            id: "2",
            name: "Due Today",
            color: "#8cb900",
            data: [
                // {
                //     id: "5",
                //     priority: "high",
                //     title: "This is title",
                //     read: ""
                // },
            ]
        },
        {
            id: "3",
            name: "Due this week",
            color: "#2fc6f6",
            data: [
                // {
                //     id: "7",
                //     priority: "low",
                //     title: "This is title",
                //     read: ""
                // },
                // {
                //     id: "8",
                //     priority: "high",
                //     title: "This is title",
                //     read: ""
                // },
            ]
        },
        {
            id: "4",
            name: "Due next week",
            color: "#55d0e0",
            data: []
        },
        {
            id: "5",
            name: "Due in two weeks",
            color: "#468ee5",
            data: [
                // {
                //     id: "11",
                //     priority: "high",
                //     title: "This is title",
                //     read: ""
                // }
            ]
        },
        {
            id: "6",
            name: "No deadline",
            color: "#a8adb4",
            data: [
                // {
                //     id: "13",
                //     priority: "low",
                //     title: "This is title",
                //     read: ""
                // },
                // {
                //     id: "14",
                //     priority: "medium",
                //     title: "This is title",
                //     read: ""
                // },
            ]
        },
    ],
    rolesList: [
        {
            id: 1,
            name: "Admin",
            slug: "admin",
            createdBy: 5,
            createdAt: "2023-11-15 00:39",
            userCreate: {
                id: 5,
                firstName: "Dominic",
                lastName: "Le",
                email: "dominic@tweetworldtravel.com",
            },
            roleToPermissions: [],
            roleHistoryEvents: [],
        },
        {
            id: 2,
            name: "User",
            slug: "user",
            createdBy: 5,
            createdAt: "2023-11-15 00:40",
            userCreate: {
                id: 5,
                firstName: "Dominic",
                lastName: "Le",
                email: "dominic@tweetworldtravel.com",
            },
            roleToPermissions: [],
            roleHistoryEvents: [],
        },
    ],
} 
import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { requestCompany } from "../interfaces/companyCRM";

const companyService = {
  getList: async (payload: { page: number, perPage: number, keyWord: string, type: string }) => {
    return await apiInstance.get(endPointAPI.COMPANY_CRM.GET_LIST + `?keyWord=${payload.keyWord}&type=${payload.type}&pageSize=${payload?.perPage}&pageNumber=${payload?.page}`);
  },
  getListSimple: async () => {
    return await apiInstance.post(endPointAPI.COMPANY_CRM.GET_LIST_SIMPLE);
  },
  getEstimateSale: async (companyId: number, financialYear: number) => {
    return await apiInstance.get(`${endPointAPI.COMPANY_CRM.GET_ESTIMATE_SALE}/${companyId}/${financialYear}`);
  },

  getCommissionLevel: async (companyId: number) => {
    return await apiInstance.get(`${endPointAPI.COMPANY_CRM.GET_COMMISSION_LEVEL}/${companyId}`);
  },

  getDetail: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_DETAIL + `/${payload.id}`
    );
  },

  createCompany: async (payload: requestCompany) => {
    return await apiInstance.post(endPointAPI.COMPANY_CRM.CREATE, payload);
  },

  updateCompany: async (payload: requestCompany, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.COMPANY_CRM.UPDATE}/${id}`,
      payload
    );
  },

  updateCompanyEstimateSale: async (payload: requestCompany, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.COMPANY_CRM.UPDATE_ESTIMATE_SALE}/${id}`,
      payload
    );
  },

  deleteCompany: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.COMPANY_CRM.DELETE + `/${payload.id}`
    );
  },

  //  End company
  storeNote: async (payload: { content: string }, company_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.COMPANY_CRM.STORE_NOTE}/${company_id}`,
      payload
    );
  },

  sendEmail: async (
    payload: { subject: string; content: string },
    company_id: number
  ) => {
    return await apiInstance.post(
      `${endPointAPI.COMPANY_CRM.SEND_EMAIL}/${company_id}`,
      payload
    );
  },

  getNotes: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_NOTES + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getHistory: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_HISTORY + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getHistoryEstimateSale: async (payload: { id: number, year: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_HISTORY_ESTIMATE_SALE + `/${payload.id}/${payload.year}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getHistoryCommissionLevel: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_HISTORY_COMMISSION_LEVEL + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getEmailSent: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_EMAIL_SENT + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  uploadDocument: async (payload: { name: string, path: string }, company_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.COMPANY_CRM.UPLOAD_DOCS}/${company_id}`,
      payload
    );
  },

  getListDocs: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_LIST_DOCUMENTS + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  deleteDocs: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.COMPANY_CRM.DELETE_DOCS + `/${payload.id}`
    );
  },
};

export default companyService;

import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { requestContact } from "../interfaces/contactCRM";

const contactService = {
  getList: async (payload: { page: number, perPage: number, keyWord: string, type?: string, companyId?: string }) => {
    let queryString = new URLSearchParams({
      pageNumber: payload.page.toString(),
      pageSize: payload.perPage.toString(),
      keyWord: payload.keyWord,
      type: payload.type ?? "",
      companyId: payload.companyId ?? ""
    }).toString();
    return await apiInstance.get(endPointAPI.CONTACT_CRM.GET_LIST +
        `?${queryString}`);
  },

  getListSimple: async () => {
    return await apiInstance.get(endPointAPI.CONTACT_CRM.GET_LIST_SIMPLE);
  },

  getListSimpleCompany: async (id: number) => {
    return await apiInstance.get(`${endPointAPI.CONTACT_CRM.GET_LIST_SIMPLE_COMPANY}/${id}`);
  },

  getKpi: async (contactId: number, financialYear: number) => {
    return await apiInstance.get(`${endPointAPI.CONTACT_CRM.GET_KPI}/${contactId}/${financialYear}`);
  },

  getEmergency: async (contactId: number) => {
    return await apiInstance.get(`${endPointAPI.CONTACT_CRM.GET_EMERGENCY}/${contactId}`);
  },

  getDetail: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_DETAIL + `/${payload.id}`
    );
  },

  createContact: async (payload: requestContact, isPassenger?: boolean) => {
    if (isPassenger)
      return await apiInstance.post(endPointAPI.CONTACT_CRM.CREATE_IN_PASSENGER, payload);
    else
      return await apiInstance.post(endPointAPI.CONTACT_CRM.CREATE, payload);
  },
  findCRMSimple: async (data: any) => {
    try {
      let response =  await apiInstance.post(`${endPointAPI.CONTACT_CRM.FIND_SIMPLE}`, data);
      return  response.data;
    } catch (e){
      return  [];
    }
  },

  findContactSimple: async (payload: { firstName: string, surname: string, email: string, dob: string, phone: string }) => {
    return await apiInstance.post(`${endPointAPI.CONTACT_CRM.FIND_SIMPLE}?isMerge=true`, payload);
  },

  updateContact: async (payload: requestContact, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.CONTACT_CRM.UPDATE}/${id}`,
      payload
    );
  },

  updateTypeContact: async (payload: { id: number, type: string }, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.CONTACT_CRM.UPDATE_TYPE}/${id}`,
      payload
    );
  },

  updateContactKpi: async (payload: requestContact, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.CONTACT_CRM.UPDATE_KPI}/${id}`,
      payload
    );
  },

  deleteContact: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.CONTACT_CRM.DELETE + `/${payload.id}`
    );
  },

  //  End contact
  storeNote: async (payload: { content: string }, contact_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.CONTACT_CRM.STORE_NOTE}/${contact_id}`,
      payload
    );
  },

  sendEmail: async (
    payload: { subject: string; content: string },
    contact_id: number
  ) => {
    return await apiInstance.post(
      `${endPointAPI.CONTACT_CRM.SEND_EMAIL}/${contact_id}`,
      payload
    );
  },

  getNotes: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_NOTES + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getHistory: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_HISTORY + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getHistoryKpi: async (payload: { id: number, year: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_HISTORY_KPI + `/${payload.id}/${payload.year}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getHistoryEmergency: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_HISTORY_EMERGENCY + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  getEmailSent: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_EMAIL_SENT + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  uploadDocument: async (payload: { name: string, path: string }, contact_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.CONTACT_CRM.UPLOAD_DOCS}/${contact_id}`,
      payload
    );
  },

  getListDocs: async (payload: { id: number, page: number, perPage: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_LIST_DOCUMENTS + `/${payload.id}?pageSize=${payload?.perPage}&pageNumber=${payload?.page}`
    );
  },

  deleteDocs: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.CONTACT_CRM.DELETE_DOCS + `/${payload.id}`
    );
  },
};

export default contactService;

// import apiInstance from "../config/apiInstance";
import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const taskService = {
  getList: async (payload: { keyWord: string, status: string, priority: string, deadlineFrom: string, deadlineTo: string, idUser: string, assignType?: string }) => {
    let queryString = new URLSearchParams({
      keyWord: payload.keyWord,
      status: payload.status,
      priority: payload.priority,
      deadlineFrom: payload.deadlineFrom,
      deadlineTo: payload.deadlineTo,
      idUser: payload.idUser,
      assignType: payload.assignType ?? ""
    }).toString();
    return await apiInstance.get(
      endPointAPI.TASK.GET_LIST + `?${queryString}`
    );
  },

  getTaskFinish: async (payload: { pageNumber: number, pageSize: number, keyWord: string, status: string, priority: string, deadlineFrom: string, deadlineTo: string, idUser: string }) => {
    let queryString = new URLSearchParams({
      keyWord: payload.keyWord,
      status: payload.status,
      priority: payload.priority,
      deadlineFrom: payload.deadlineFrom,
      deadlineTo: payload.deadlineTo,
      pageNumber: (payload.pageNumber + 1).toString(),
      pageSize: payload.pageSize.toString(),
      idUser: payload.idUser
    }).toString();
    return await apiInstance.get(
      endPointAPI.TASK.GET_LIST_COMPLETE + `?${queryString}`);
  },

  getAnaLyticsTask: async (payload: { userId: number,type: string, date: string  }) => {
    let queryString = new URLSearchParams({
      userId: payload.userId.toString(),
      type: payload.type,
      date: payload.date
    }).toString();
    return await apiInstance.get(
      endPointAPI.TASK.ANALYTICS + `?${queryString}`
    );
  },



  getTaskHistory: async (idTask: number, payload: { pageNumber: number, pageSize: number }) => {
    let queryString = new URLSearchParams({
      pageNumber: (payload.pageNumber + 1).toString(),
      pageSize: payload.pageSize.toString()
    }).toString();
    return await apiInstance.get(
      endPointAPI.TASK.HISTORY + `/${idTask}` + `?${queryString}`
    );
  },


  createTask: async (payload: any) => {
    return await apiInstance.post(endPointAPI.TASK.CREATE, payload);
  },

  getDetailById: async (payload: { id: number | undefined }) => {
    return await apiInstance.get(
      endPointAPI.TASK.DETAIL + `/${payload.id}`
    );
  },

  updateTask: async (payload: any, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.TASK.UPDATE}/${id}`,
      payload
    );
  },

  finishTask: async (id: number) => {
    return await apiInstance.put(
      `${endPointAPI.TASK.FINISH}/${id}`
    );
  },

  changeFavorite: async (payload: any, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.TASK.FAVORITE}/${id}`,
      payload
    );
  },

  deleteTask: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.TASK.DELETE + `/${payload.id}`
    );
  },

  getSimple: async () => {
    return await apiInstance.post(endPointAPI.USER.FIND_SIMPLE);
  },


  updateDeadline: async (payload: any, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.TASK.UPDATE_DEADLINE}/${id}`,
      payload
    )
  },

  getListActivitiesByTaskId: async (id: number | undefined) => {
    return await apiInstance.get(
      endPointAPI.ACTIVITIES.GET_LIST + `/${id}`
    );
  },

  createActivity: async (payload: any) => {
    return await apiInstance.post(endPointAPI.ACTIVITIES.CREATE, payload);
  },

  deleteActivity: async (id: number) => {
    return await apiInstance.delete(
      endPointAPI.ACTIVITIES.DELETE + `/${id}`
    );
  },

  startActivity: async (payload: any, id: number, idTask: number) => {
    return await apiInstance.put(
      `${endPointAPI.ACTIVITIES.START_ACTIVITY}/${id}/${idTask}`,
      payload
    )
  },

  pendingActivity: async (payload: any, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.ACTIVITIES.PENDING_ACTIVITY}/${id}`,
      payload
    )
  },

  finishActivity: async (payload: any, id: number, idTask: number) => {
    return await apiInstance.put(
      `${endPointAPI.ACTIVITIES.FINISH_ACTIVITY}/${id}/${idTask}`,
      payload
    )
  },

  logActivity: async (payload: any) => {
    return await apiInstance.post(
      `${endPointAPI.ACTIVITIES.LOG}`,
      payload
    )
  },

  listLogActivity: async (id: number | undefined) => {
    return await apiInstance.get(
      `${endPointAPI.ACTIVITIES.LIST_LOG}/${id}`
    )
  },
}

export default taskService;
import { Autocomplete, Box, Grid, Paper, TextField, Typography } from '@mui/material'
import { SearchTaskBarPagination, UserItem } from '../../../interfaces/kanban';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import data from '../../../mockdata/data';
import { ClearAll, Search } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { userAuth } from '../../../interfaces/authentication';

interface Props {
    setDataSearch: (payload: SearchTaskBarPagination) => void;
    dataSearch: SearchTaskBarPagination;
    loading: boolean;
    agentList: { label: string; value: number }[];
    status: string
}

const SearchBarPagination = ({ setDataSearch, dataSearch, loading, agentList, status = "COMPLETE" }: Props) => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors }
    } = useForm<SearchTaskBarPagination>({
        mode: "onChange"
    });

    const submit: SubmitHandler<SearchTaskBarPagination> = async (data) => {


        const payload = {
            keyWord: data.keyWord,
            status: data.status ?? "",
            priority: data.priority ?? "",
            deadlineFrom: startDate,
            deadlineTo: endDate,
            idUser: data.idUser != undefined ? data.idUser.toString() : user.id.toString(),
            pageSize: data.pageSize,
            pageNumber: data.pageNumber
        }
        setDataSearch(payload);
    }

    useEffect(() => {
        reset(dataSearch);
    }, [dataSearch]);


    return (
        <Paper sx={{ p: 2, mb: 3, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
            <form onSubmit={handleSubmit(submit)}>
                <Box mb={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#174d75" }}>Search Bar</Typography>
                    <LoadingButton
                        variant="text"
                        loading={loading}
                        endIcon={<ClearAll />}
                        onClick={() => {
                            setDataSearch({
                                keyWord: "",
                                status: status,
                                priority: "",
                                deadlineFrom: "",
                                deadlineTo: "",
                                idUser: user.id.toString(),
                                pageNumber: 0,
                                pageSize: 10
                            });
                            setStartDate("");
                            setEndDate("");
                        }}
                    >
                        Clear
                    </LoadingButton>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xl={3} md={3} xs={12}>
                        <TextField
                            {...register("keyWord")}
                            label={'Keyword'}
                            placeholder='Enter and task name or content...'
                            fullWidth
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={errors.keyWord !== undefined}
                            helperText={errors.keyWord !== undefined ? errors.keyWord.message : ""}
                        />
                    </Grid>
                    <Grid item xl={2} md={2} xs={6}>
                        <Controller
                            control={control}
                            name="priority"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Autocomplete
                                        disablePortal
                                        id="priority"
                                        size="small"
                                        options={data.priority}
                                        onChange={(event, value) => {
                                            onChange(value);
                                        }}
                                        value={value ? value : null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={'Priority'}
                                                placeholder='Select Priority'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.priority !== undefined}
                                                helperText={
                                                    errors.priority !== undefined ? errors.priority.message : ""
                                                }
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xl={3} md={3} xs={12}>

                        <Box>
                            <input type="hidden" {...register("deadlineFrom")} />
                            <input type="hidden" {...register("deadlineTo")} />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    localeText={{ start: 'Deadline From', end: 'Deadline To' }}
                                    slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true } } }}
                                    className={"date-range"}
                                    value={[dayjs(startDate), dayjs(endDate)]}
                                    onChange={(newValue) => {
                                        setStartDate(newValue[0] ? newValue[0].format("YYYY-MM-DD") : "");
                                        setEndDate(newValue[1] ? newValue[1].format("YYYY-MM-DD") : "")
                                    }}
                                    format={"DD/MM/YYYY"}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid item xl={2} md={2} xs={6}>
                        <Controller
                            control={control}
                            name="idUser"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Autocomplete
                                        disablePortal
                                        loading={loading}
                                        id="idUser"
                                        size="small"
                                        options={agentList}
                                        onChange={(event, value) => {
                                            onChange(value?.value);
                                        }}
                                        disabled={(user.roleId === 1 || user.roleId === 2 || user.roleId === 3) ? false : true}
                                        value={agentList.find(item => item.value === Number(value))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={'User'}
                                                placeholder='Select User'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.idUser !== undefined}
                                                helperText={
                                                    errors.idUser !== undefined ? errors.idUser.message : ""
                                                }
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item md={1} xs={6}>
                        <LoadingButton
                            loading={loading}
                            endIcon={<Search />}
                            type='submit' size='large' variant='contained' color="primary"
                        >
                            Search
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>

        </Paper>
    )
}

export default SearchBarPagination
import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import {
    CommonDataReservation,
    FormPackageDetail, FormUpdateReservationOverview,
    ManualReservationRequestType, PackageCancelFormData,
    PackageItem, PackageProgramDetail
} from "../interfaces/reservation";
import {
    ReservationAddMorePassengerDataInterface, ReservationAddMoreRoomDataInterface,
    ReservationPackageContent,
    ReservationPackageContentOfPost
} from "../interfaces/reservationDataInput";

const reservationService = {
    // manual
    createManualReservation: async (payload: ReservationPackageContent) => {
        return await apiInstance.post(endPointAPI.RESERVATION.MANUAL.STORE_MANUAL_CUSTOM, payload);
    },
    createManualReservationWithProduct: async (payload: ReservationPackageContentOfPost) => {
        return await apiInstance.post(endPointAPI.RESERVATION.MANUAL.STORE_MANUAL_WITH_PRODUCT, payload);

    },

    getListBookingPage: async (payload: { page: number, perPage: number, keyWord: string, type?: string, companyId?: string }) => {
        let queryString = new URLSearchParams({
            pageNumber: payload.page.toString(),
            pageSize: payload.perPage.toString(),
            keyWord: payload.keyWord,
            type: payload.type ?? "",
            companyId: payload.companyId ?? ""
        }).toString();
        return await apiInstance.get(endPointAPI.RESERVATION.LIST_BOOKING +
            `?${queryString}`);
    },


    getListQuotesPage: async (payload: { page: number, perPage: number, keyWord: string, type?: string, companyId?: string }) => {
        let queryString = new URLSearchParams({
            pageNumber: payload.page.toString(),
            pageSize: payload.perPage.toString(),
            keyWord: payload.keyWord,
            type: payload.type ?? "",
            companyId: payload.companyId ?? ""
        }).toString();
        return await apiInstance.get(endPointAPI.RESERVATION.LIST_QUOTATION +
            `?${queryString}`);
    },


    getListBooking: async () => {
        try {
            let request = await apiInstance.get(endPointAPI.RESERVATION.LIST_BOOKING);
            return request.data;
        } catch (e) {
            return null;
        }
    },
    getListQuotation: async () => {
        try {
            let request = await apiInstance.get(endPointAPI.RESERVATION.LIST_QUOTATION);
            return request.data;
        } catch (e) {
            return null;
        }
    },
    commonData: async () => {
        try {
            let request = await apiInstance.get(endPointAPI.RESERVATION.COMMON_DATA);
            return request.data;
        } catch (e) {
            return null;
        }
    },
    detail: async (ref: string) => {
        try {
            let request = await apiInstance.get(endPointAPI.RESERVATION.DETAIL + "/" + ref);
            return request.data;
        } catch (e) {
            return null;
        }
    },
    updateOverview: async (id: number, data: FormUpdateReservationOverview) => {
        try {
            let request = await apiInstance.put(endPointAPI.RESERVATION.UPDATE_OVERVIEW + "/" + id, data);
            return request.data;
        } catch (e) {
            return null;
        }
    },
    roomService: {
        addMorePassenger: async (roomId: number, data: ReservationAddMorePassengerDataInterface) => {
            try {
                await apiInstance.post(endPointAPI.RESERVATION.ROOM.ADD_MORE_PASSENGER + `/${roomId}`, data);
                return true;
            } catch (e) {
                return false;
            }
        },
        deletePassenger: async (roomId: number, passengerId: number) => {
            try {
                await apiInstance.delete(endPointAPI.RESERVATION.ROOM.DELETE_PASSENGER + `/${roomId}/${passengerId}`);
                return true;
            } catch (e) {
                return false;
            }
        },
        deleteRoom: async (roomId: number) => {
            try {
                await apiInstance.delete(endPointAPI.RESERVATION.ROOM.DELETE_ROOM + `/${roomId}`);
                return true;
            } catch (e) {
                return false;
            }
        },
        addMore: async (packageId: number, data: ReservationAddMoreRoomDataInterface) => {
            try {
                await apiInstance.post(endPointAPI.RESERVATION.ROOM.ADD_MORE_ROOM + `/${packageId}`, data);
                return true;
            } catch (e) {
                return false;
            }
        }
    },
    packageService: {
        getPackageByReference: async (reference: string) => {
            try {
                let request = await apiInstance.get(endPointAPI.RESERVATION.PACKAGE.LIST_BY_REFERENCE + '/' + reference);
                return request.data;
            } catch (e) {
                return [];
            }
        },
        cancel: async (id: number, data: PackageCancelFormData) => {
            try {
                let request = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.CANCEL + "/" + id, data);
                return request.data
            } catch (e) {
                return null;
            }
        },
        update: async (id: number, data: FormPackageDetail) => {
            try {
                let request = await apiInstance.put(endPointAPI.RESERVATION.PACKAGE.UPDATE + '/' + id, data);
                return request.data;
            } catch (e) {
                return null;
            }
        },
        programDetail: async (id: number) => {
            try {
                let request = await apiInstance.get(endPointAPI.RESERVATION.PACKAGE.PROGRAM + '/' + id);
                return request.data;
            } catch (e) {
                return null;
            }
        },
        updateProgram: async (id: number, data: ReservationPackageContent) => {
            try {
                let response = await apiInstance.put(endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM + `/${id}`, data);
                return response.data;
            } catch (e) {
                return null
            }
        },
        getInvoicePdf: async (payload: { reservationId: number, passengerId?: number, roomId?: number, packageId?: number, isAgent?: boolean }) => {
            try {
                let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.GET_INVOICE, payload);
                return response.data;
            } catch (e) {
                return null
            }
        },
        getListInvoicePdf: async (payload: { packageId?: number }) => {
            try {
                let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.GET_LIST_INVOICE}/${payload.packageId}`);
                return response.data;
            } catch (e) {
                return null
            }
        },
        storeInvoicePdf: async (payload: { reservationId: number, passengerId?: number, roomId?: number, packageId?: number, isAgent?: boolean, status?: string }) => {
            try {
                let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.STORE_INVOICE, payload);
                return response;
            } catch (e: any) {
                return e?.response;
            }
        },
        deleteInvoicePdf: async (id: number) => {
            try {
                let response = await apiInstance.delete(`${endPointAPI.RESERVATION.PACKAGE.DELETE_INVOICE}/${id}`);
                return response.data;
            } catch (e) {
                return null
            }
        },
        getInvoiceData: async (id: number) => {
            try {
                let response = await apiInstance.post(`${endPointAPI.RESERVATION.PACKAGE.GET_INVOICE_DATA}`, { id: id });
                return response.data;
            } catch (e) {
                return null
            }
        },
        historyPackageInvoice: async (id: number) => {
            try {
                let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.HISOTRY_INVOICE}/${id}`);
                return response.data;
            } catch (e) {
                return null
            }
        },
        storePackageNote: async (payload: { packageId: number, content: string, type: string }) => {
            try {
                let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.STORE_NOTE, payload);
                return response;
            } catch (e: any) {
                return e?.response;
            }
        },
        packageNote: async (id: number) => {
            try {
                let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.LIST_NOTE}/${id}`);
                return response.data;
            } catch (e) {
                return null
            }
        },
    },
    passengerService: {
        price: async (id: number) => {
            try {
                let request = await apiInstance.get(endPointAPI.RESERVATION.PASSENGER.PRICE + "/" + id);
                return request.data;
            } catch (e) {
                return null;
            }
        },
        setContact: async (id: number, data: { contactId: number }) => {
            return await apiInstance.put(endPointAPI.RESERVATION.PASSENGER.SET_CONTACT + '/' + id, data);
        }
    }

}
export default reservationService;

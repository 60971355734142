import { Box, Button, Card, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, } from '@mui/material';
import DrawerCustom from '../../../components/drawer';
import { lowercaseLetter } from '../../../utils/function';
import { CheckCircleOutline, ExpandLess, ExpandMore, Folder } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import taskService from '../../../services/taskService';
import { ActivityItem, ActivityLog, TaskItem, UserItem } from '../../../interfaces/kanban';
import moment from 'moment';
import ListActivities from '../activities/ListActivities';
import FormActivities from '../activities/FormActivities';
import Swal from 'sweetalert2';
import { userAuth } from '../../../interfaces/authentication';
import { EmailTemplate } from '../../../components/mail/EmailTemplate';
import mailService from '../../../services/mailService';
import ModalHistory from './TaskHistoryModal';
import CustomBreadcrumbs from '../../../components/breadcrumbs/CustomBreadcrumbs';
import 'suneditor/dist/css/suneditor.min.css';
import userService from '../../../services/userService';

interface Props {
    handleClose: () => void;
    isViewTask: boolean;
    idDetail?: number;
    callBackList: () => void;
}

const ViewTask = ({ handleClose, isViewTask, idDetail, callBackList }: Props) => {

    const [data, setData] = useState<TaskItem>();
    const [loadingData, setloadingData] = useState(false);
    const [dataActivities, setDataActivities] = useState<ActivityItem[]>([]);
    const [open, setOpen] = useState(false);
    const [dataAlert, setDataAlert] = useState([]);
    const [loadingMail, setLoadingMail] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [checked, setChecked] = useState(false);
    const [agentList, setAgentList] = useState<{ label: string; value: number }[]>([]);
    const [dataActivityLog, setDataActivityLog] = useState<ActivityLog[]>([]);

    const getDetails = async () => {

        try {
            let [detaiData, activityData, resAgentList] = await Promise.all([
                taskService.getDetailById({ id: idDetail }),
                taskService.getListActivitiesByTaskId(idDetail),
                userService.getSimple()
            ]);
            if (detaiData.status === 200) {
                setloadingData(false);
                setData(detaiData.data);
            }
            if (activityData.status === 200) {
                setloadingData(false);
                setDataActivities(activityData.data);
            }
            if (resAgentList.status === 200) {
                setAgentList(resAgentList.data.map((item: UserItem) => ({
                    label: item.firstName + ' ' + item.lastName,
                    value: item.id
                })));
            }
        } catch (error) {
            console.log(error);

        }

    };
    const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

    const getListActivityLog = async () => {
        const res = await taskService.listLogActivity(idDetail);
        if (res.status === 200) {
            setDataActivityLog(res.data);
        }
    }

    const callBack = () => {
        getDetails();
        callBackList();
        setChecked(false);
        getListActivityLog();
    }

    const handleFinishTask = async (id: number) => {
        try {
            const finish = await taskService.finishTask(id);
            if (finish.status === 200) {
                getDetails();
                callBackList();
                Swal.fire({
                    icon: "success",
                    title: "Task Finished !",
                    text: "Please review it in List Task Complete"
                });
            }
        } catch (error: any) {
            setDataAlert(error.response.data);
            setOpen(true);
        }
    }
    const handleCloseArlert = () => {
        setOpen(false);
    };

    const sendmail = async () => {
        setLoadingMail(true);
        const dataUser = [];
        dataUser.push({
            email: data?.responsiblePersonInfo.email,
            name: data?.responsiblePersonInfo.firstName + ' ' + data?.responsiblePersonInfo.lastName
        })
        if (data?.responsiblePersonExtraInfo) {
            dataUser.push(...data?.responsiblePersonExtraInfo.map((item: UserItem) => ({
                email: item.email,
                name: item.firstName + ' ' + item.lastName
            })));
        }

        let payload = {
            subject: "Task Reminder",
            mailFromAddress: "no-reply@tweetworldtravel.com",
            mailFromName: "Task System",
            content: EmailTemplate,
            users: dataUser,
            task_id: data?.id,
            task_name: data?.title,
            task_deadline: data?.deadline ? moment(data?.deadline).format('ddd, DD-MMM-YYYY') : "No Deadline",
            mailer: "SMTP",
            host: "email-smtp.ap-southeast-2.amazonaws.com",
            port: "587",
            encryption: "TLS",
            username: "AKIAR5SMLLJCTKS357LB",
            password: "BGofkw9SLb7Ev6kfXgfXy5kw/476HKFkFQMfLM8kyj8u",
            companyCode: localStorage.getItem("companyCode"),
            barerToken: localStorage.getItem("accessToken")
        }

        const res = await mailService.sendMail(payload);
        if (res.status === 200) {
            setLoadingMail(false);
        }
    }

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    useEffect(() => {
        if (isViewTask) {
            getDetails();
            getListActivityLog();
            setloadingData(true);

        }
    }, [isViewTask]);

    const calculateTotalTime = (entries: ActivityLog[]) => {
        let totalSeconds = 0;
        let startTime: Date | null = null;
        entries.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        entries.forEach((entry) => {
            if (entry.status === 'START') {
                startTime = new Date(entry.createdAt);
            } else if ((entry.status === 'PAUSE' || entry.status === 'FINISH') && startTime) {
                const endTime = new Date(entry.createdAt);
                totalSeconds += (endTime.getTime() - startTime.getTime()) / 1000;
                startTime = null;
            }
        });
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return hours > 0 || minutes > 0 || seconds > 0 ? `${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""} ${seconds > 0 ? `${seconds}s` : ""}` : null;
    };

    const renderPriority = (item: string) => {
        switch (item) {
            case 'LOW':
                return <Box sx={{ mr: 2, mt: 1, fontSize: 13, fontWeight: 600, background: "#a8adb4", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>{lowercaseLetter(item)}</Box>
            case 'MEDIUM':
                return <Box sx={{ mr: 2, mt: 1, fontSize: 13, fontWeight: 600, background: "#9dcf00", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>{lowercaseLetter(item)}</Box>
            case 'HIGH':
                return <Box sx={{ mr: 2, mt: 1, fontSize: 13, fontWeight: 600, background: "#ff5752", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>{lowercaseLetter(item)}</Box>
            default:
                return null
        }
    }


    return (
        <Box>
            <DrawerCustom title={`Task #${idDetail} - ${data?.status}`} handleClose={handleClose} openDrawer={isViewTask}>
                <DialogContent sx={{ background: "#efecec", px: 2 }}>
                    {loadingData ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
                        <CircularProgress />
                    </Box> : <Box sx={{ position: "relative" }}>
                        {data && <Grid container spacing={2}>
                            <Grid item md={9}>
                                <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
                                    <Grid container spacing={2} mb={3}>
                                        <Grid item md={10}>
                                            <Typography variant='h6'>{data?.title}</Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography>Priority:</Typography>
                                                {renderPriority(data.priority)}
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Box mb={5}>
                                        <div className='sun-editor-editable view-editor-custom' dangerouslySetInnerHTML={{ __html: data?.content }} />
                                    </Box>

                                    {data.linkToFeature && <Box mb={5} display={'flex'} alignItems={'center'}><Typography sx={{ mr: 1 }}>Link to: </Typography>
                                        <CustomBreadcrumbs linkToFeature={data?.linkToFeature} featureLinkType={data?.featureLinkType} featureAssign={data?.featureAssign} data={data} />
                                    </Box>}

                                    <Grid item md={12} textAlign={'right'}>
                                        <Tooltip title={data.userApproveInfo && data.userApproveInfo.id != user.id ? `Only ${data.userApproveInfo.firstName} ${data.userApproveInfo.lastName} can approve tasks ` : "Finish Task"}>
                                            <span>
                                                <Button variant='contained'
                                                    disabled={data.status === "COMPLETE" || data.userApproveInfo && data.userApproveInfo.id != user.id}
                                                    color="success"
                                                    startIcon={<CheckCircleOutline />}
                                                    onClick={() => handleFinishTask(data.id)}
                                                >
                                                    Finish
                                                </Button>
                                            </span>

                                        </Tooltip>
                                    </Grid>
                                    <Divider sx={{ mb: 3 }} textAlign="left"><Chip label="ADD ACTIVITIES" onClick={handleChange} sx={{ cursor: "pointer", background: "#174d75", color: "#fff" }} /></Divider>

                                    <Box mb={5}>
                                        <Grid container spacing={2} mt={1}>
                                            <Grid item xs={12}>
                                                {/* <Box sx={{ mt: 1, background: "#174d7521", p: 2, borderRadius: 3, boxShadow: "0px 1px 4px 1px #174d756b" }} >
                                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                <Button sx={{ fontWeight: 600 }} onClick={handleChange} endIcon={checked ? <ExpandLess /> : <ExpandMore />}>CLICK TO ADD ACTIVITIES</Button>
                                                </Box> */}
                                                <Collapse in={checked}>
                                                    <Box sx={{ mt: 1, background: "#174d7521", p: 2, borderRadius: 3, boxShadow: "0px 1px 4px 1px #174d7521" }} >
                                                        <FormActivities status={data.status} idDetail={data.id} callBack={callBack} agentList={agentList} />
                                                    </Box>
                                                </Collapse>
                                                {/* </Box> */}
                                            </Grid>
                                        </Grid>
                                        {/* <FormActivities status={data.status} idDetail={data.id} callBack={callBack} /> */}
                                    </Box>
                                    {dataActivities && dataActivities.length > 0 && <ListActivities dataActivityLog={dataActivityLog} data={dataActivities} idTask={data.id} callBack={callBack} taskStatus={data.status} />}
                                </Paper>

                            </Grid>
                            <Grid item md={3}>
                                <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
                                    <Divider><Chip sx={{ fontWeight: 600, fontSize: 14, color: "#0009" }} label="Task History" onClick={() => setShowHistory(true)}></Chip></Divider>
                                </Paper>

                                {
                                    data.documents && data.documents != "[]" && <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
                                        <Box sx={{ background: "#d0d1d0", p: 2, mb: 1, borderRadius: 3, fontWeight: 700, textAlign: 'center', color: "#174d75" }}>Documents</Box>
                                        <List dense>
                                            {JSON.parse(data.documents).map((item: any, index: number) => (
                                                <ListItem
                                                    key={index}
                                                    secondaryAction={
                                                        <Button variant='text' href={item.link} target='_blank'>View</Button>
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemIcon sx={{ color: "#174d75", minWidth: 30 }}>
                                                        <Folder />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.title}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Paper>
                                }

                                <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3, position: "sticky", top: 10, zIndex: 10 }}>
                                    <Box sx={{ background: "#86cfda", p: 2, borderRadius: 3, fontWeight: 700, textAlign: 'center' }}>Task Summary</Box>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Created on:</TableCell>
                                                    <TableCell>{data.createdAt && moment(data.createdAt).format('ddd, DD-MMM-YYYY')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Responsible person:</TableCell>
                                                    <TableCell><Chip size='small' color='default' label={`${data.responsiblePersonInfo.firstName} ${data.responsiblePersonInfo.lastName}`} /></TableCell>
                                                </TableRow>
                                                {data.responsiblePersonExtraInfo.length > 0 && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Responsible person extra:</TableCell>
                                                    <TableCell>
                                                        {data.responsiblePersonExtraInfo.map((item, index) =>
                                                            <span style={{ fontWeight: 600 }} key={index}> {index == 0 ? "" : "|"} {item.firstName} {item.lastName}</span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>}
                                                {data.userApproveInfo && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Approve Person</TableCell>
                                                    <TableCell>
                                                        <Chip size='small' color='default' label={`${data.userApproveInfo.firstName} ${data.userApproveInfo.lastName}`} />
                                                    </TableCell>
                                                </TableRow>}
                                                {data.reminderAt && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Reminder</TableCell>
                                                    <TableCell>{data.reminderAt && moment(data.reminderAt).format('ddd, DD-MMM-YYYY')}</TableCell>
                                                </TableRow>}
                                                {data.planStart && data.planEnd && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Planned Duration</TableCell>
                                                    <TableCell>{moment(data.planEnd).diff(data.planStart, 'hours')} hours</TableCell>
                                                </TableRow>}
                                                {data.planStart && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Start on</TableCell>
                                                    <TableCell>{data.planStart && moment(data.planStart).format('ddd, DD-MMM-YYYY')}</TableCell>
                                                </TableRow>}
                                                {data.planEnd && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Planned Finish</TableCell>
                                                    <TableCell>{data.planEnd && moment(data.planEnd).format('ddd, DD-MMM-YYYY')}</TableCell>
                                                </TableRow>}
                                                {data.deadline && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Deadline</TableCell>
                                                    <TableCell>{data.deadline && moment(data.deadline).format('ddd, DD-MMM-YYYY')}</TableCell>
                                                </TableRow>}
                                                {calculateTotalTime(dataActivityLog) && <TableRow>
                                                    <TableCell sx={{ fontWeight: 600 }}>Total Time Action</TableCell>
                                                    <TableCell>{calculateTotalTime(dataActivityLog)}</TableCell>
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>}
                    </Box>
                    }
                </DialogContent>

            </DrawerCustom>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: "#ff322c" }}>
                    Cannot complete because there is an activity progress !
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dataAlert.map((item: any) => (
                            <Box key={item.id} sx={{ color: "#000" }}>{item.activityName} - {item.status}</Box>
                        ))}
                        <Typography sx={{ mt: 3 }}>Complete the activities before completing the main task.</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseArlert}>Close</Button>
                </DialogActions>
            </Dialog>

            <ModalHistory idTask={Number(idDetail)} showHistory={showHistory} setShowHistory={setShowHistory} />
        </Box>

    )
}

export default ViewTask
import { createContext, useContext } from "react";
import { UserItem } from "../interfaces/kanban";
export type GlobalContent = {
  userList: UserItem[];
  setUserList: (
    data: UserItem[]
  ) => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  userList: [],
  setUserList: () => {},
});

export const useGlobalContext = () => useContext(MyGlobalContext);

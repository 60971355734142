import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataListPagination  } from "../../interfaces/reservation";
import contactService from "../../services/contactService";
import companyService from "../../services/companyService";


interface Props {
    isOpen: boolean
    handleClose: () => void
    handleSelect: (data: any) => void
    type: string
}


const SelectCRM = ({ isOpen, handleClose, handleSelect, type }: Props) => {

    const [reservations, setReservations] = useState<DataListPagination>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [keyword, setKeyword] = useState("");
    const [valueInputSearch, setValueInputSearch] = useState("");

    const fetchData = async () => {
        if (isOpen) {
            Swal.fire({
                title: "Fetching Data...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading();
                },
            });
            if (type == "Contact") {
                let resData: DataListPagination = (await contactService.getList({
                    page: page + 1,
                    perPage: rowsPerPage,
                    keyWord: keyword
                })).data;
                setReservations(resData);
            } else {
                let resData: DataListPagination = (await companyService.getList({
                    page: page + 1,
                    perPage: rowsPerPage,
                    keyWord: keyword,
                    type: ""
                })).data;
                setReservations(resData);
            }

            Swal.close();
        } else {
            clearData();
        }
    }

    const clearData = () => {
        setPage(0);
        setRowsPerPage(50);
        setKeyword("");
        setReservations(undefined);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, keyword]);

    useEffect(() => {
        if (isOpen) {
            fetchData();
        } else {
            clearData();
        }
    }, [isOpen])

    return <Drawer className={"drawer-common"} open={isOpen} anchor={"right"} sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '100%', md: '85%', lg: '80%', xl: '60%' },
            boxSizing: 'border-box',
        },
    }}>
        <DialogTitle
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                m: 0,
                p: 2,
                bgcolor: "#103551",
                color: "#fff",
                fontWeight: "bold"
            }}
        >
            {type}
            <IconButton aria-label="close" onClick={handleClose}>
                <Close sx={{ color: "#fff" }} />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "rgb(239, 236, 236)" }}>
            <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
                {reservations && <TableContainer sx={{ mt: 2 }} component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <TextField onChange={(e) => setValueInputSearch(e.target.value)} value={valueInputSearch} size={"small"} sx={{ width: "250px", mb: "0 !important" }} label={"Keyword"} placeholder={"Enter keyword..."} InputLabelProps={{ shrink: true }} />
                                            <Button disabled={!valueInputSearch || !(valueInputSearch.trim())} variant={"contained"} sx={{ ml: 1 }} 
                                            onClick={() => {
                                                setKeyword(valueInputSearch)
                                                setPage(0);
                                                }}>Search</Button>
                                            <Button color={"inherit"} variant={"contained"} sx={{ ml: 1 }} onClick={() => {
                                                setKeyword("");
                                                setValueInputSearch("");
                                            }}>Clear</Button>
                                        </Box>
                                        <TablePagination count={reservations.total} onPageChange={handleChangePage} page={page}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={(e) => {setRowsPerPage(Number(e.target.value)); setPage(0);}}
                                            rowsPerPageOptions={[20, 50, 100, 150]} />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"center"}>#</TableCell>
                                <TableCell align={"left"}>{type} Name</TableCell>
                                <TableCell align={"center"}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reservations.data.map((item, index) => <TableRow key={index}>
                                <TableCell sx={{ py: 1 }} align={"center"}>{rowsPerPage * (page) + index + 1}</TableCell>
                                <TableCell sx={{ py: 1 }} align={"left"}>{item.name}</TableCell>
                                <TableCell sx={{ py: 1 }} align={"center"}>
                                    <Button onClick={() => handleSelect(item)} size={"small"} variant={"contained"}>Select</Button>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Paper>
        </DialogContent>

    </Drawer>
}

export default SelectCRM
const endPointAPI = {
    TASK: {
        GET_LIST: "/task/get-list",
        GET_LIST_COMPLETE: "/task/task-list",
        CREATE: "/task/store",
        DELETE: "/task/delete",
        DETAIL: "/task/detail",
        UPDATE: "/task/update",
        FAVORITE: "/task/favorite",
        UPDATE_DEADLINE: "/task/update-deadline",
        FINISH: "/task/finish-task",
        HISTORY: "/task/history",
        ANALYTICS: "/task/analytics/total-task"

    },
    ACTIVITIES: {
        GET_LIST: "/task/activities/get-list",
        CREATE: "/task/activities/store",
        START_ACTIVITY: "/task/activities/start-activity",
        PENDING_ACTIVITY: "/task/activities/pending-activity",
        FINISH_ACTIVITY: "/task/activities/finish-activity",
        DELETE: "/task/activities/delete",
        LOG: "/task/activities/activity-log",
        LIST_LOG: "/task/activities/get-activity-log",
    },
    MAIL: {
        CONFIG: "/setting/email-config/find-all",
        SEND: "/set-schedule",
    },
    USER: {
        CURRENT: "/user/current",
        FIND_SIMPLE: "/user/find-simple"
    },
    COMPANY_CRM: {
        GET_LIST: "/crm/company",
        GET_LIST_SIMPLE: "/crm/company/list-simple",
        GET_ESTIMATE_SALE: "/crm/company/estimate-sale",
        GET_COMMISSION_LEVEL: "/crm/company/commission-level",
        GET_DETAIL: "/crm/company/show",
        CREATE: "/crm/company/store",
        CREATE_ESTIMATE_SALE: "/crm/company/store-estimate-sale",
        UPDATE: "/crm/company/update",
        UPDATE_ESTIMATE_SALE: "/crm/company/update-estimate-sale",
        DELETE: "/crm/company/delete",
        STORE_NOTE: "/crm/company/store-note",
        SEND_EMAIL: "/crm/company/send-email",
        GET_NOTES: "/crm/company/notes",
        GET_HISTORY: "/crm/company/history",
        GET_HISTORY_ESTIMATE_SALE: "/crm/company/history-estimate-sale",
        GET_HISTORY_COMMISSION_LEVEL: "/crm/company/history-commission-level",
        GET_EMAIL_SENT: "/crm/company/email-sent",
        GET_LIST_DOCUMENTS: "/crm/company/documents",
        UPLOAD_DOCS: "/crm/company/upload-document",
        DELETE_DOCS: "/crm/company/delete-document",
    },
    CONTACT_CRM: {
        GET_LIST: "/crm/contact",
        GET_LIST_SIMPLE: "/crm/contact/list-simple",
        GET_LIST_SIMPLE_COMPANY: "/crm/contact/list-simple-company",
        GET_KPI: "/crm/contact/kpi",
        GET_EMERGENCY: "/crm/contact/emergency",
        GET_DETAIL: "/crm/contact/show",
        CREATE: "/crm/contact/store",
        CREATE_IN_PASSENGER: "/crm/contact/store-in-passenger",
        CREATE_KPI: "/crm/contact/store-kpi",
        UPDATE: "/crm/contact/update",
        UPDATE_TYPE: "/crm/contact/update-type",
        UPDATE_KPI: "/crm/contact/update-kpi",
        DELETE: "/crm/contact/delete",
        STORE_NOTE: "/crm/contact/store-note",
        SEND_EMAIL: "/crm/contact/send-email",
        GET_NOTES: "/crm/contact/notes",
        GET_HISTORY: "/crm/contact/history",
        GET_HISTORY_KPI: "/crm/contact/history-kpi",
        GET_HISTORY_EMERGENCY: "/crm/contact/history-emergency",
        GET_EMAIL_SENT: "/crm/contact/email-sent",
        GET_LIST_DOCUMENTS: "/crm/contact/documents",
        UPLOAD_DOCS: "/crm/contact/upload-document",
        DELETE_DOCS: "/crm/contact/delete-document",
        FIND_SIMPLE: "/crm/contact/find-simple",
    },
    PRODUCT: {
        // tour
        GET_LIST: "/product/tour",
        GET_SIMPLE_LIST_TOUR: "/product/tour/find-simple",
        GET_SIMPLE_LIST_GOLF: "/product/golf/find-simple",
        GET_DETAIL: "/product/tour/detail",
        CREATE: "/product/tour/store",
        UPDATE: "/product/tour/update",
        CHECK_TRIPCODE_USED: "/product/tour/check-trip-code-used",
        DELETE: "/product/tour/delete",
        GET_DESTINATIONS_BY_COUNTRIES: "/product/location/destinations-by-countries",
        GET_LIST_COUNTRIES: "/product/location/countries",


        // tour price
        GET_LIST_TOUR_PRICE: "/product/price/tour/list",
        GET_AVAILABLE_TOUR_PRICE: "/product/price/tour/available",
        GET_DETAIL_TOUR_PRICE: "/product/price/tour/detail",
        CREATE_TOUR_PRICE: "/product/price/tour/store",
        UPDATE_TOUR_PRICE: "/product/price/tour/update",
        DELETE_TOUR_PRICE: "/product/price/tour/delete",
        IMPORT_TOUR_PRICE: "/product/price/tour/import",
        UPDATE_TOUR_MULTI_PRICE: "/product/price/tour/multi-update",


        // golf
        GET_LIST_GOLF: "/product/golf",
        GET_DETAIL_GOLF: "/product/golf/detail",
        CREATE_GOLF: "/product/golf/store",
        UPDATE_GOLF: "/product/golf/update",
        CHECK_TRIPCODE_USED_GOLF: "/product/golf/check-trip-code-used",
        DELETE_GOLF: "/product/golf/delete",
        // golf price
        GET_LIST_GOLF_PRICE: "/product/price/golf/list",
        GET_DETAIL_GOLF_PRICE: "/product/price/golf/detail",
        CREATE_GOLF_PRICE: "/product/price/golf/store",
        UPDATE_GOLF_PRICE: "/product/price/golf/update",
        DELETE_GOLF_PRICE: "/product/price/golf/delete",
        IMPORT_GOLF_PRICE: "/product/price/golf/import",
        UPDATE_GOLF_MULTI_PRICE: "/product/price/golf/multi-update",
        //  categories
        GET_LIST_CATEGORIES: "/product/category/get-by-type",
        CREATE_CATEGORY: "/product/category/store",
        UPDATE_CATEGORY: "/product/category/update",
        DELETE_CATEGORY: "/product/category/delete",
    },
    RESERVATION: {
        LIST_BOOKING: "reservation/bookings",
        LIST_QUOTATION: "reservation/quotations",
        MANUAL: {
            STORE_MANUAL_CUSTOM: "/reservation/store-manual-custom",
            STORE_MANUAL_WITH_PRODUCT: "/reservation/store-with-product",
        },
        PACKAGE: {
            LIST_BY_REFERENCE: "/reservation/package/find-by-reference",
            UPDATE: "/reservation/package/update",
            PROGRAM: "/reservation/package/program",
            CANCEL: "/reservation/package/cancel",
            UPDATE_PROGRAM: "/reservation/package/update-program",
            GET_INVOICE: "/reservation/package-invoice/get-invoice-pdf",
            GET_LIST_INVOICE: "/reservation/package-invoice/get-list-invoice-pdf",
            STORE_INVOICE: "/reservation/package-invoice/store-invoice-pdf",
            DELETE_INVOICE: "/reservation/package-invoice/delete-invoice",
            GET_INVOICE_DATA: "/reservation/package/invoice-data",
            HISOTRY_INVOICE: "/reservation/package-invoice/package-invoice-history",
            STORE_NOTE: "/reservation/package/store-package-note",
            LIST_NOTE: "/reservation/package/package-note",
        },
        PASSENGER: {
            PRICE: "/reservation/passenger/price",
            SET_CONTACT: "reservation/passenger/set-contact"
        },
        ROOM: {
            ADD_MORE_PASSENGER: "/reservation/room/add-more-passenger",
            DELETE_PASSENGER: "/reservation/room/delete-passenger",
            DELETE_ROOM: "/reservation/room/delete",
            ADD_MORE_ROOM: "/reservation/room/add-more"
        },
        COMMON_DATA: "/reservation/common-data",
        DETAIL: "/reservation/detail",
        UPDATE_OVERVIEW: "/reservation/update/overview"

    },
    MASTER: {
        INFO: "setting/find-company-setting"
    },
}

export default endPointAPI;
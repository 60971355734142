export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const lowercaseLetter = (string: string) => {
    return string[0] + string.slice(1).toLowerCase();
}


// export function getDateRangeOfWeek(weekNo: number, year: number) {
//     var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
//     d1 = new Date("" + year + "");
//     numOfdaysPastSinceLastMonday = d1.getDay() - 1;
//     d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
//     d1.setDate(d1.getDate() + 7 * (weekNo - d1.getWeek()));
//     rangeIsFrom = d1.getMonth() + 1 + "-" + d1.getDate() + "-" + d1.getFullYear();
//     d1.setDate(d1.getDate() + 6);
//     rangeIsTo = d1.getMonth() + 1 + "-" + d1.getDate() + "-" + d1.getFullYear();
//     return [rangeIsFrom, rangeIsTo];
//   }
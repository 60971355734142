import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OpenInNew } from '@mui/icons-material';
import { TaskItem } from '../../interfaces/kanban';
import { Tooltip } from '@mui/material';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.10),
            // cursor: "pointer"
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.22),
        },
    };
}) as typeof Chip;

const StyledBreadcrumb2 = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        color: "#468ee5",
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: "underline",
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.10),
            // cursor: "pointer"
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.22),
        },
    };
}) as typeof Chip;


interface Props {
    linkToFeature: string;
    featureLinkType: string;
    featureAssign: string;
    data: TaskItem
}

const CustomBreadcrumbs = ({ linkToFeature, featureLinkType, featureAssign, data }: Props) => {

    // function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
    //     event.preventDefault();
    //     console.info('You clicked a breadcrumb.');
    // }


    return (
        <div role="presentation" >
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb label={linkToFeature} />
            <StyledBreadcrumb label={featureLinkType} />
            {data.featureLinkType == "Booking" && <Tooltip title="Click here to show details"><StyledBreadcrumb2
                component="a"
                href={process.env.REACT_APP_ENV === "DEVELOPMENT" ? `https://dev-subscribers.tkgplatform.com.au/booking/${featureAssign}` : `https://staging-subscriber.tkgplatform.com.au/booking/${featureAssign}`}
                target='_blank'
                label={featureAssign}
                icon={<OpenInNew fontSize="small" />}
                sx={{ cursor: "pointer" }}
            /></Tooltip>}
            {data.featureLinkType == "Quotation" && <Tooltip title="Click here to show details"><StyledBreadcrumb2
                component="a"
                href={process.env.REACT_APP_ENV === "DEVELOPMENT" ? `https://dev-subscribers.tkgplatform.com.au/quotation/${featureAssign}` : `https://staging-subscriber.tkgplatform.com.au/quotation/${featureAssign}`}
                target='_blank'
                label={featureAssign}
                icon={<OpenInNew fontSize="small" />}
                sx={{ cursor: "pointer" }}
            /></Tooltip>}
            {data.featureLinkType == "Contact" && <Tooltip title="Click here to show details"><StyledBreadcrumb2
                component="a"
                href={process.env.REACT_APP_ENV === "DEVELOPMENT" ? `https://dev-subscribers.tkgplatform.com.au/dashboard/crm/contact?open=${featureAssign}` : `https://staging-subscriber.tkgplatform.com.au/dashboard/crm/contact?open=${featureAssign}`}
                target='_blank'
                label={`${JSON.parse(data?.contactInfo?.type)} - ${data?.contactInfo?.name}`}
                icon={<OpenInNew fontSize="small" />}
                sx={{ cursor: "pointer" }}
            /></Tooltip>}
            {/* href={`${process.env.REACT_APP_TYPE}`} */}
            {data.featureLinkType == "Company" && <Tooltip title="Click here to show details"><StyledBreadcrumb2
                component="a"
                href={process.env.REACT_APP_ENV === "DEVELOPMENT" ? `https://dev-subscribers.tkgplatform.com.au/dashboard/crm/company?open=${featureAssign}` : `https://staging-subscriber.tkgplatform.com.au/dashboard/crm/company?open=${featureAssign}`}
                target='_blank'
                label={`${data?.companyInfo?.type} - ${data?.companyInfo?.name}`}
                icon={<OpenInNew fontSize="small" />}
                sx={{ cursor: "pointer" }}
            /></Tooltip>}

        </Breadcrumbs>
    </div>
    )
}

export default CustomBreadcrumbs
import { PauseCircleOutline, PlayCircleOutline, StopCircleOutlined, TimerOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import taskService from '../../services/taskService';
import { userAuth } from '../../interfaces/authentication';
import Swal from 'sweetalert2';
import { useState } from 'react';


interface Props {
    idActivity: number
    statusActivity: string;
    callBack: () => void;
    createBy: number;
    idTask: number;
    statusTask: string;
    assignUser: number | undefined;
}

const ActivitiesTimer = ({ idActivity, statusActivity, callBack, createBy, idTask, statusTask, assignUser }: Props) => {

    const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

    const startTime = async () => {
        const payload = {
            status: "INPROGRESS",
        }
        try {
            const startItem = await taskService.startActivity(payload, idActivity, idTask);
            if (startItem.status == 200) {
                callBack();
                const logStart = await taskService.logActivity({
                    activityId: idActivity,
                    taskId: idTask,
                    userId: user.id,
                    status: "START"
                });
            }
        } catch (error: any) {
            Swal.fire({
                title: `Your task is now running !`,
                html: `
                    <span>Activity <b>${error.response.data.activityName}</b></span> / 
                    <span>Task <b>${error.response.data.taskName}</b></span>
                    <p>${error.response.data.id != idTask ? `<a href="/?taskid=${error.response.data.id}" target="_blank"><b>Go to the Task</b></a>` : ""}</p>
                    <p style="color:red">Do you want to stop this activity to start ?</p>
                     `,
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
                confirmButtonColor: "#ff5752",
            }).then(async (res) => {
                if (res?.isConfirmed) {
                    const payload = {
                        status: "PAUSE",
                    }
                    const pendingItem = await taskService.pendingActivity(payload, error.response.data.activityId);
                    if (pendingItem.status == 200) {
                        startTime();
                        const logStart = await taskService.logActivity({
                            activityId: error.response.data.activityId,
                            taskId: error.response.data.id,
                            userId: user.id,
                            status: "PAUSE"
                        });
                    }
                }
            });
        }

    }

    const pauseTime = async () => {
        const payload = {
            status: "PAUSE",
        }
        const pendingItem = await taskService.pendingActivity(payload, idActivity);
        if (pendingItem.status == 200) {
            const logStart = await taskService.logActivity({
                activityId: idActivity,
                taskId: idTask,
                userId: user.id,
                status: "PAUSE"
            });
            callBack();
        }
    }

    const finishTime = async () => {
        const payload = {
            status: "COMPLETE",
        }
        const finishItem = await taskService.finishActivity(payload, idActivity, idTask);
        if (finishItem.status == 200) {
            const logStart = await taskService.logActivity({
                activityId: idActivity,
                taskId: idTask,
                userId: user.id,
                status: "FINISH"
            });
            callBack();
        }
    }

    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            {statusActivity === "INPROGRESS" ? <Typography data-text={`${statusActivity}...`} className='text-time-animation'>{`${statusActivity}...`}</Typography> : <Typography sx={{ opacity: 0 }}>.</Typography>}
            <Stack direction={'row'} alignItems={'center'}>
                {statusActivity != "PENDING" &&
                    <Tooltip title="Finish">
                        <span>
                            <IconButton color='error' onClick={finishTime} disabled={statusActivity === "COMPLETE"}>
                                <StopCircleOutlined />
                            </IconButton>
                        </span>
                    </Tooltip>}

                {statusActivity === "INPROGRESS" && <Tooltip title="Pause">
                    <span>
                        <IconButton color='primary' onClick={pauseTime} disabled={statusTask === "COMPLETE"}>
                            <PauseCircleOutline />
                        </IconButton>
                    </span>
                </Tooltip>}

                {statusActivity === "PAUSE" && <Tooltip title="Start">
                    <span>
                        <IconButton color='primary' onClick={startTime} disabled={statusTask === "COMPLETE"}>
                            <PlayCircleOutline />
                        </IconButton>
                    </span>
                </Tooltip>}

                {statusActivity === "PENDING" && <Tooltip title="Start">
                    <span>
                        <IconButton color='primary' onClick={startTime} disabled={statusTask === "COMPLETE"}>
                            <PlayCircleOutline />
                        </IconButton>
                    </span>
                </Tooltip>}
            </Stack>


        </Stack>

    )
}

export default ActivitiesTimer
import { ArrowOutward, AttachFile, CallReceived, DeleteOutline, KeyboardArrowRight } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import ActivitiesTimer from '../../../components/timer/ActivitiesTimer'
import { ActivityItem, ActivityLog } from '../../../interfaces/kanban';
import moment from 'moment';
import taskService from '../../../services/taskService';
import Swal from 'sweetalert2';
import { userAuth } from '../../../interfaces/authentication';
import 'suneditor/dist/css/suneditor.min.css';
import { useState } from 'react';
import { convertUTCtoLocalTime2 } from '../../../utils';

interface Props {
    data: ActivityItem[];
    callBack: () => void;
    idTask: number;
    taskStatus: string;
    dataActivityLog: ActivityLog[]
}

const ListActivities = ({ data, idTask, callBack, taskStatus, dataActivityLog }: Props) => {
    const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

    const [openDetail, setOpenDetail] = useState(false);
    const [dataDetail, setDataDetail] = useState("");

    const handleClose = () => {
        setOpenDetail(false);
    };

    const handleDelete = (id: number, title: string) => {
        Swal.fire({
            title: `Delete - ${title}`,
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#468ee5",
        }).then(async (res) => {
            if (res?.isConfirmed) {
                const deleteData = await taskService.deleteActivity(id);
                if (deleteData.status === 200) {
                    callBack();
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Delete Activity Successfully !",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
        });
    };

    const calculateTotalTime = (entries: ActivityLog[]) => {
        let totalSeconds = 0;
        let startTime: Date | null = null;
        entries.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        entries.forEach((entry) => {
            if (entry.status === 'START') {
                startTime = new Date(entry.createdAt);
            } else if ((entry.status === 'PAUSE' || entry.status === 'FINISH') && startTime) {
                const endTime = new Date(entry.createdAt);
                totalSeconds += (endTime.getTime() - startTime.getTime()) / 1000;
                startTime = null;
            }
        });
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return hours > 0 || minutes > 0 || seconds > 0 ? `${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""} ${seconds > 0 ? `${seconds}s` : ""}` : null;
    };



    return (
        <Box px={1} sx={{ background: "#e3e3e39e", borderRadius: 2 }}>
            <Grid container spacing={3} >
                <Grid item md={4} sx={{ paddingTop: "8px !important" }}>
                    <Typography sx={{ background: "#468ee5", p: 2, fontWeight: 600, color: "#fff", borderRadius: "15px 15px 0px 0px" }} variant='body1'>Pending</Typography>
                    <Box sx={{ py: 1 }}>
                        {data.filter((item) => item.status === "PENDING").map((itemActivity) =>
                        (<Card key={itemActivity.id} elevation={2} sx={{ borderRadius: 3, mb: 1 }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: '#468ee5' }} aria-label="recipe">{itemActivity.createdBy.firstName.charAt(0)}</Avatar>
                                }
                                action={<Tooltip title="Delete">
                                    <IconButton onClick={() => handleDelete(itemActivity.id, itemActivity.title)} color='error' aria-label="settings" disabled={taskStatus === "COMPLETE" || itemActivity.createdBy.id != user.id}>
                                        <DeleteOutline />
                                    </IconButton>
                                </Tooltip>}
                                title={`${itemActivity.createdBy.firstName} ${itemActivity.createdBy.lastName}`}
                                subheader={moment(itemActivity.createdAt).format('ddd, DD-MMM-YYYY')}
                                titleTypographyProps={{ fontWeight: 600 }}
                                sx={{ pb: 1 }}
                            />
                            <CardContent sx={{ pb: "10px !important" }}>
                                <div className='sun-editor-editable view-editor-custom activities-content-custom'
                                    dangerouslySetInnerHTML={{ __html: itemActivity?.title }}
                                />
                                {itemActivity?.title.length > 300 && <Button variant="text"
                                    endIcon={<KeyboardArrowRight />}
                                    sx={{ mt: 1 }}
                                    onClick={() => {
                                        setOpenDetail(true);
                                        setDataDetail(itemActivity?.title);
                                    }}>View More</Button>}

                                {
                                    itemActivity?.assignUsers && itemActivity?.assignUsers.length > 0 &&
                                    <Box sx={{ mb: 1, mt: 2 }}>
                                        {itemActivity.assignUsers.map((item: any, index: number) => (
                                            <Chip size='small' sx={{ mb: 1, mr: 1 }} key={index} label={`${item?.firstName} ${item?.lastName}`}></Chip>
                                        ))}
                                    </Box>
                                }
                                {
                                    itemActivity?.documentations && itemActivity?.documentations != "[]" &&
                                    <Box>
                                        {JSON.parse(itemActivity.documentations).map((item: any, index: number) => (
                                            <Stack key={index} direction={'row'} alignItems={'center'} spacing={1}>
                                                <AttachFile sx={{ color: "#468ee5", fontSize: 18 }} />
                                                <Box
                                                    component={"a"}
                                                    href={item.link}
                                                    target='_blank'
                                                    sx={{ fontWeight: 500, color: "#468ee5", fontSize: 14, "&:hover": { color: "#b70c07" } }}
                                                >
                                                    {item.title}
                                                </Box>
                                            </Stack>

                                        ))}
                                    </Box>
                                }
                                <Box sx={{ borderTop: "1px solid #0000001f", mb: 1, mt: 1 }} />
                                <ActivitiesTimer
                                    idActivity={itemActivity.id}
                                    statusActivity={itemActivity.status}
                                    statusTask={taskStatus}
                                    callBack={callBack}
                                    createBy={itemActivity.createdBy.id}
                                    assignUser={itemActivity?.assignUsers && itemActivity?.assignUsers.length > 0 ? itemActivity.assignUsers.find((item: any) => item.id == user.id)?.id : 0}
                                    idTask={idTask}
                                />
                            </CardContent>
                        </Card>)
                        )}
                    </Box>
                </Grid>
                <Grid item md={4} sx={{ paddingTop: "8px !important" }}>
                    <Typography sx={{ background: "#8cb900", p: 2, fontWeight: 600, color: "#fff", borderRadius: "15px 15px 0px 0px" }} variant='body1'>In Progress</Typography>
                    <Box sx={{ py: 1 }}>
                        {data.filter((item) => item.status === "INPROGRESS" || item.status === "PAUSE").map((itemActivity) =>
                        (<Card key={itemActivity.id} elevation={2} sx={{ borderRadius: 3, mb: 1, background: itemActivity.status === "PAUSE" ? "#fff6f6" : "#fff", border: itemActivity.status === "PAUSE" ? "1px solid #468ee5" : "1px solid #8cb900" }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: '#8cb900' }} aria-label="recipe">{itemActivity.createdBy.firstName.charAt(0)}</Avatar>
                                }
                                title={`${itemActivity.createdBy.firstName} ${itemActivity.createdBy.lastName}`}
                                subheader={moment(itemActivity.createdAt).format('ddd, DD-MMM-YYYY')}
                                titleTypographyProps={{ fontWeight: 600 }}
                                sx={{ pb: 1 }}
                            // action={
                            //     <Box sx={{ fontSize: 12, fontWeight: 600, background: itemActivity.status === "PAUSE" ? "#468ee5" : "#8cb900", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>
                            //         {itemActivity.status}
                            //     </Box>
                            // }
                            />
                            <CardContent sx={{ pb: "10px !important" }}>
                                <div className='sun-editor-editable view-editor-custom activities-content-custom'
                                    dangerouslySetInnerHTML={{ __html: itemActivity?.title }}
                                />
                                {itemActivity?.title.length > 300 && <Button variant="text"
                                    endIcon={<KeyboardArrowRight />}
                                    sx={{ mt: 1 }}
                                    onClick={() => {
                                        setOpenDetail(true);
                                        setDataDetail(itemActivity?.title);
                                    }}>View More</Button>}
                                {
                                    itemActivity?.assignUsers && itemActivity?.assignUsers.length > 0 &&
                                    <Box sx={{ mb: 1, mt: 2 }}>
                                        {itemActivity.assignUsers.map((item: any, index: number) => (
                                            <Chip size='small' sx={{ mb: 1, mr: 1 }} key={index} label={`${item?.firstName} ${item?.lastName}`}></Chip>
                                        ))}
                                    </Box>
                                }
                                {
                                    itemActivity?.documentations && itemActivity?.documentations != "[]" &&
                                    <Box>
                                        {JSON.parse(itemActivity.documentations).map((item: any, index: number) => (
                                            <Stack key={index} direction={'row'} alignItems={'center'} spacing={1}>
                                                <AttachFile sx={{ color: "#468ee5", fontSize: 18 }} />
                                                <Box
                                                    component={"a"}
                                                    href={item.link}
                                                    target='_blank'
                                                    sx={{ fontWeight: 500, color: "#468ee5", fontSize: 14, "&:hover": { color: "#b70c07" } }}
                                                >
                                                    {item.title}
                                                </Box>
                                            </Stack>

                                        ))}
                                    </Box>
                                }

                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1 }}>
                                        <span style={{ fontWeight: 700 }}>Start:</span> {convertUTCtoLocalTime2(itemActivity.startAt)}
                                    </Typography>
                                    {itemActivity.pendingAt && <KeyboardArrowRight sx={{ fontSize: 20, color: "#0000008a" }} />}
                                    {itemActivity.pendingAt && <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1 }}>
                                        <span style={{ fontWeight: 700 }}>Pause:</span> {convertUTCtoLocalTime2(itemActivity.pendingAt)}
                                    </Typography>}
                                </Stack>
                                <Box sx={{ borderTop: "1px solid #0000001f", mb: 1, mt: 1 }} />
                                <ActivitiesTimer
                                    idActivity={itemActivity.id}
                                    statusActivity={itemActivity.status}
                                    statusTask={taskStatus}
                                    callBack={callBack}
                                    createBy={itemActivity.createdBy.id}
                                    idTask={idTask}
                                    assignUser={itemActivity?.assignUsers && itemActivity?.assignUsers.length > 0 ? itemActivity.assignUsers.find((item: any) => item.id == user.id)?.id : 0}
                                />
                            </CardContent>
                        </Card>)
                        )}
                    </Box>
                </Grid>
                <Grid item md={4} sx={{ paddingTop: "8px !important" }}>
                    <Typography sx={{ background: "#2fc6f6", p: 2, fontWeight: 600, color: "#fff", borderRadius: "15px 15px 0px 0px" }} variant='body1'>Complete</Typography>
                    <Box sx={{ py: 1 }}>
                        {data.filter((item) => item.status === "COMPLETE").map((itemActivity) =>
                        (<Card key={itemActivity.id} elevation={2} sx={{ borderRadius: 3, mb: 1 }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: '#2fc6f6' }} aria-label="recipe">{itemActivity.createdBy.firstName.charAt(0)}</Avatar>
                                }
                                title={`${itemActivity.createdBy.firstName} ${itemActivity.createdBy.lastName}`}
                                subheader={moment(itemActivity.createdAt).format('ddd, DD-MMM-YYYY')}
                                titleTypographyProps={{ fontWeight: 600 }}
                                sx={{ pb: 1 }}
                            />
                            <CardContent sx={{ pb: "10px !important" }}>
                                <div className='sun-editor-editable view-editor-custom activities-content-custom'
                                    dangerouslySetInnerHTML={{ __html: itemActivity?.title }}
                                />
                                {itemActivity?.title.length > 300 && <Button variant="text"
                                    endIcon={<KeyboardArrowRight />}
                                    sx={{ mt: 1 }}
                                    onClick={() => {
                                        setOpenDetail(true);
                                        setDataDetail(itemActivity?.title);
                                    }}>View More</Button>}
                                {
                                    itemActivity?.assignUsers && itemActivity?.assignUsers.length > 0 &&
                                    <Box sx={{ mb: 1, mt: 2 }}>
                                        {itemActivity.assignUsers.map((item: any, index: number) => (
                                            <Chip size='small' sx={{ mb: 1, mr: 1 }} key={index} label={`${item?.firstName} ${item?.lastName}`}></Chip>
                                        ))}
                                    </Box>
                                }
                                {
                                    itemActivity?.documentations && itemActivity?.documentations != "[]" &&
                                    <Box>
                                        {JSON.parse(itemActivity.documentations).map((item: any, index: number) => (
                                            <Stack key={index} direction={'row'} alignItems={'center'} spacing={1}>
                                                <AttachFile sx={{ color: "#468ee5", fontSize: 18 }} />
                                                <Box
                                                    component={"a"}
                                                    href={item.link}
                                                    target='_blank'
                                                    sx={{ fontWeight: 500, color: "#468ee5", fontSize: 14, "&:hover": { color: "#b70c07" } }}
                                                >
                                                    {item.title}
                                                </Box>
                                            </Stack>

                                        ))}
                                    </Box>
                                }
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1 }}>
                                        <span style={{ fontWeight: 700 }}>Start:</span> {convertUTCtoLocalTime2(itemActivity.startAt)}
                                    </Typography>
                                    <KeyboardArrowRight sx={{ fontSize: 20, color: "#0000008a" }} />
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1 }}>
                                        <span style={{ fontWeight: 700 }}>Finish:</span> {convertUTCtoLocalTime2(itemActivity.finishAt)}
                                    </Typography>
                                </Stack>
                                {calculateTotalTime(dataActivityLog.filter((item) => item.activityId == itemActivity.id)) && <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1 }}>
                                    <span style={{ fontWeight: 700 }}>Total Time:</span> {calculateTotalTime(dataActivityLog.filter((item) => item.activityId == itemActivity.id))}
                                </Typography>}
                                <Box sx={{ borderTop: "1px solid #0000001f", mb: 1, mt: 1 }} />
                                <ActivitiesTimer
                                    idActivity={itemActivity.id}
                                    statusActivity={itemActivity.status}
                                    statusTask={taskStatus}
                                    callBack={callBack}
                                    createBy={itemActivity.createdBy.id}
                                    idTask={idTask}
                                    assignUser={itemActivity?.assignUsers && itemActivity?.assignUsers.length > 0 ? itemActivity.assignUsers.find((item: any) => item.id == user.id)?.id : 0}
                                />
                            </CardContent>
                        </Card>)
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={openDetail}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"lg"}
            >
                <DialogContent>
                    <div className='sun-editor-editable view-editor-custom'
                        dangerouslySetInnerHTML={{ __html: dataDetail }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ListActivities
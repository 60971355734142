import {
    DragDropContext,
    Draggable,
    DraggableProvided,
} from "react-beautiful-dnd";
import { FunctionComponent, useEffect, useState } from "react";
import { MainContainerProps, TaskItem } from "../../../interfaces/kanban";
import List from "../../../components/list";
import { Box, Button, Grid, Stack } from "@mui/material";
import CardCustom from "../../../components/card";
import ModalTask from "./ModalTask";
import { getDeadlineByCol } from "../../../utils";
import moment from "moment";
import Swal from "sweetalert2";
import taskService from "../../../services/taskService";
import { Add } from "@mui/icons-material";
import CompleteTable from "./CompleteTable";
import UpComingTask from "./UpComingTask";

const DragList: FunctionComponent<MainContainerProps> = (props) => {
    const [items, setItems] = useState(props.data);
    const [idDrag, setIdDrag] = useState<TaskItem>();

    const [selectItems, setSelectItems] = useState<{
        items: any[];
        selectedArray: string;
    }>({ items: [], selectedArray: "" });
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);
    const [openUpComing, setOpenUpComing] = useState(false);

    const removeFromList = (list: any, index: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result];
    };

    const selectFromList = (
        event: any,
        item: any,
        index: number,
        draggedArray: string
    ) => {
        let currentSelectedItems = { ...selectItems };
        if (selectItems.selectedArray !== draggedArray) {
            setSelectItems({ items: [], selectedArray: draggedArray });
            currentSelectedItems = { items: [], selectedArray: draggedArray };
        }

        let result = [];
        if (event.target.checked) {
            let data = [];
            data.push({ ...item, sourceIndex: index });
            result = [...currentSelectedItems.items, ...data];
        } else {
            let data = [...currentSelectedItems.items];
            var itemIndex = data.findIndex((el) => el.id === item.id);
            data.splice(itemIndex, 1);
            result = [...data];
        }
        result.sort((a, b) => {
            return a.sourceIndex - b.sourceIndex;
        });
        setSelectItems({ items: result, selectedArray: draggedArray });
    };

    const addToList = (list: any, index: any, element: any) => {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    };

    const onDragEnd = async (result: any) => {



        if (!result.destination) {
            return;
        }

        const listCopy: any[] = [...items];

        if (
            selectItems.items.findIndex(
                (d) => d.sourceIndex == result.source.index
            ) !== -1 &&
            selectItems.selectedArray === result.source.droppableId
        ) {
            selectItems.items.map((el, i) => {
                let sourceId = listCopy.findIndex(
                    (el) => el.id === result.source.droppableId
                );
                if (sourceId !== -1) {
                    const sourceList = listCopy[sourceId].data;
                    let sourceIndex = el.sourceIndex - i;
                    const [removedElement, newSourceList] = removeFromList(
                        sourceList,
                        sourceIndex
                    );
                    listCopy[sourceId].data = newSourceList;

                    let destinationIndex = listCopy.findIndex(
                        (el) => el.id === result.destination.droppableId
                    );
                    if (destinationIndex !== -1) {
                        const destinationList = listCopy[destinationIndex].data;
                        listCopy[destinationIndex].data = addToList(
                            destinationList,
                            result.destination.index + i,
                            removedElement
                        );
                    }
                }
            });
            setSelectItems({ items: [], selectedArray: "" });
        } else {
            let sourceId = listCopy.findIndex(
                (el) => el.id === result.source.droppableId
            );
            const sourceList = listCopy[sourceId].data;

            if (sourceId !== -1) {
                const [removedElement, newSourceList] = removeFromList(
                    sourceList,
                    result.source.index
                );
                listCopy[sourceId].data = newSourceList;
                let destinationIndex = listCopy.findIndex(
                    (el) => el.id === result.destination.droppableId
                );
                if (destinationIndex !== -1) {
                    const destinationList = listCopy[destinationIndex].data;
                    listCopy[destinationIndex].data = addToList(
                        destinationList,
                        result.destination.index,
                        removedElement
                    );
                }
            }
        }
        setItems(listCopy);
        props.onChange(listCopy);

        if (result.destination.droppableId && idDrag) {
            var setDeadLine = getDeadlineByCol(result.destination.droppableId);
            if (moment(idDrag?.deadline).format("YYYY-MM-DD") === setDeadLine && result.destination.droppableId === "3") {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Cannot be changed because today is already Weekend !",
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                let dataDL = setDeadLine == null ? setDeadLine : moment(setDeadLine).format("YYYY-MM-DDTHH:mm:ss");
                await taskService.updateDeadline({ deadline: dataDL }, idDrag.id).then(res => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your Task has been saved !",
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            }
            props.callBack();
        }
    };

    useEffect(() => {
        if (props.data) {
            setItems(props.data);
        }
    }, [props.data]);

    return (
        <Box>
            <Box mb={3} display={'flex'} justifyContent={'space-between'}>
                <Button onClick={() => setOpenDrawer(true)} variant="contained" endIcon={<Add />}>
                    Create
                </Button>
                <Stack direction={'row'} spacing={2}>
                    <Button variant="contained" color="info" onClick={() => setOpenUpComing(true)}>Upcoming Tasks</Button>
                    <Button variant="contained" color="info" onClick={() => setOpenComplete(true)}>List Task Complete</Button>

                </Stack>
            </Box>
            <ModalTask
                handleClose={() => setOpenDrawer(false)}
                isOpen={openDrawer}
                callBack={props.callBack}
            />
            <CompleteTable handleClose={() => setOpenComplete(false)} isOpen={openComplete} />
            <UpComingTask handleClose={() => setOpenUpComing(false)} isOpen={openUpComing} />
            
            <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                <Grid container spacing={1}>
                    {items.map((el) => {
                        return (
                            <List
                                key={`drop_box_${el.id}`}
                                color={el.color}
                                centerTitle={props.centerTitle}
                                title={props.title ? el.name : ""}
                                onDragEnd={(e) => onDragEnd(e)}
                                id={el.id}
                            >
                                {el.data.map((item: any, index: number) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id + ""}
                                        index={index}
                                        isDragDisabled={el.id === '1'}
                                    >
                                        {(provided: DraggableProvided | any) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="drag_item_container"
                                            >
                                                <input
                                                    disabled={!props.multiple}
                                                    id={`${el.id}_${index}`}
                                                    type={"checkbox"}
                                                    checked={
                                                        selectItems.selectedArray === el.id &&
                                                        selectItems.items.findIndex(
                                                            (e) => e.id === item.id
                                                        ) !== -1
                                                    }
                                                    onChange={(event) =>
                                                        selectFromList(event, item, index, el.id)
                                                    }
                                                />
                                                <CardCustom
                                                    callBack={props.callBack}
                                                    color={el.color}
                                                    draggerImg={props.draggerImg}
                                                    data={item}
                                                    inputId={`${el.id}_${index}`}
                                                    setSelectedId={setIdDrag}
                                                >
                                                    {props.children ? props.children : null}
                                                </CardCustom>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                            </List>
                        );
                    })}
                </Grid>
            </DragDropContext>
        </Box>
    );
};

export default DragList;

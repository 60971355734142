import { useLocation, useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";

export default function TKGTreeView() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    // <></>
    <Stack direction={'row'} spacing={3} mb={3}>
      <Button variant={location.pathname === "/" ? "contained" : "outlined"}
        sx={{ fontWeight: 600, borderRadius: 2 }}
        onClick={() => navigate("/")}
        disableElevation>Tasks</Button>
      <Button variant={location.pathname === "/analytics" ? "contained" : "outlined"}
        sx={{ fontWeight: 600, borderRadius: 2 }}
        onClick={() => navigate("/analytics")}
        disableElevation>Analytics</Button>
    </Stack>

  );
}

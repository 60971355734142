import React, { useEffect, useState } from "react";
import DragList from "./kanban/DragList";
import data from "../../mockdata/data";
import taskService from "../../services/taskService";
import moment from "moment";
import SearchBar from "./kanban/SearchBar";
import { SearchTaskBar } from "../../interfaces/kanban";
import { userAuth } from "../../interfaces/authentication";

const getListData = (itemFilter: any, listData: any[]) => {

  const checkDataDate = (itemPlanStart: string, itemDeadline: string) => {

    if (moment(itemDeadline).isAfter(moment(), "days")) {
      return moment().format("YYYY-MM-DD");
    } 
    return itemDeadline;

    // if (moment(itemPlanStart).isBefore(moment(), "days")) {
    //   return itemDeadline;
    // } else if (moment(itemPlanStart).isSame(moment(), "days")) {
    //   return itemPlanStart;
    // } else if (moment(itemPlanStart).isAfter(moment(), "days")) {
    //   return itemPlanStart;
    // } 
    // return itemDeadline;

  }

  // item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline

  switch (itemFilter.name) {
    case "Overdue":
      return listData.filter((item) => moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).isBefore(moment(), "days"));
    case "Due Today":
      return listData.filter((item) => moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).isSame(moment(), "days"));
    case "Due this week":
      return listData.filter((item) =>
        moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).startOf("week").isSame(moment(), "week") && !moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).isSame(moment(), "days") && !moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).isBefore(moment(), "days")
      );
    case "Due next week":
      return listData.filter((item) =>
        moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).startOf('isoWeek').isSame(moment().add(1, 'weeks'), "week")
      );
    case "Due in two weeks":
      return listData.filter((item) =>
        moment(item.planStart ? checkDataDate(item.planStart ,item.deadline) : item.deadline).startOf('isoWeek').isSame(moment().add(2, 'weeks'), "week") // get task in 2 weeks
        // !moment(item.planStart ?? item.deadline).startOf('isoWeek').isSame(moment().add(1, 'weeks'), "week") && !moment(item.planStart ?? item.deadline).startOf("week").isSame(moment(), "week") && !moment(item.planStart ?? item.deadline).isSame(moment(), "days") && !moment(item.planStart ?? item.deadline).isBefore(moment(), "days") && item.planStart ?? item.deadline != null // get all task
      );
    case "No deadline":
      return listData.filter((item) =>
        item.deadline == null
      );
    default:
      return [];
  }
};

const HomePage = () => {
  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

  const [loading, setLoading] = useState(false);
  const [dataTask, setDataTask] = useState<any>([]);
  const [dataSearch, setDataSearch] = useState<SearchTaskBar>({
    keyWord: "",
    status: "",
    priority: "",
    deadlineFrom: "",
    deadlineTo: "",
    idUser: user.id.toString(),
    assignType: "",
  });


  const fetchData = async () => {
    setLoading(true);
    try {
      const listTaskItem = await taskService.getList(dataSearch);
      if (listTaskItem.status === 200) {
        const dataTask = data.dataKanban.map((item) => {
          const listData = getListData(item, listTaskItem.data);
          return {
            ...item,
            data: listData.map((item: any) => {
              return ({ ...item, id: String(item.id) })
            }),
          };
        });
        setDataTask(dataTask);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }


  };




  const callBack = () => {
    fetchData();
  }

  // console.log(dataSearch);


  useEffect(() => {
    fetchData();
    document.title = "Tasks";
  }, [dataSearch]);

  return (
    <React.Fragment>
      <SearchBar setDataSearch={setDataSearch} dataSearch={dataSearch} loading={loading} />

      <DragList
        onChange={(data) => {
          setDataTask(data);
        }}
        width="100%"
        height="100%"
        data={dataTask}
        multiple
        draggerImg
        title
        centerTitle
        callBack={() => callBack()}
      />


    </React.Fragment>
  );
};

export default HomePage;

import { Add, Delete, Remove } from '@mui/icons-material'
import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HandleActivity } from '../../../interfaces/kanban';
import * as yup from "yup";
import taskService from '../../../services/taskService';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import TextSunEditor from '../../../components/TextSunEditor/TextSunEditor';
import { makeid } from '../../../utils';
import validationMessage from '../../../constants/validationMessage';
import UploaderCustom from '../../../components/Upload/UploaderCustom';

const validationSchema = yup.object().shape({
    title: yup.string().required("Please enter something..."),
    documents: yup.array(yup.object({
        title: yup.string().required(validationMessage.required),
        link: yup.string().required(validationMessage.required),
    })),
});

interface Props {
    idDetail: number;
    callBack: () => void;
    status: string;
    agentList: { label: string; value: number }[];
}

const FormActivities = ({ idDetail, status, callBack, agentList }: Props) => {

    const [loading, setLoading] = useState(false);
    const [dataResetEditor, setDataResetEditor] = useState('');
    const [keyReset, setKeyReset] = useState("");

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        control,
        formState: { errors }
    } = useForm<HandleActivity>({
        mode: "onChange",
        resolver: yupResolver(validationSchema),

    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "documents",
    });


    const submit: SubmitHandler<HandleActivity> = async (data) => {
        setLoading(true);

        const documents = data.documents ? JSON.stringify(data.documents) : "";
        const assignIds = data.assignIds ? data.assignIds.map(String) : [];

        const payload = {
            title: data.title,
            taskId: idDetail,
            status: "PENDING",
            documents: documents,
            assignIds: assignIds,
            isPause: false,
        }

        await taskService.createActivity(payload).then(res => {
            reset();
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Create activity success !",
                showConfirmButton: false,
                timer: 1500
            });
            callBack();
            setLoading(false);
            setDataResetEditor(makeid(10));
            setKeyReset(new Date().toISOString());
            setValue('documents',[]);
        })
    }

    
    return (

        <Box component={'form'} sx={{ mt: 1 }} onSubmit={handleSubmit(submit)}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Controller
                        control={control}
                        name="assignIds"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    key={keyReset}
                                    disablePortal
                                    disableClearable
                                    id="assignIds"
                                    multiple
                                    defaultValue={(agentList ?? []).filter(item => value?.includes(item.value))}
                                    onChange={(event, value) => {
                                        onChange(value.map(item => item.value));
                                    }}
                                    size="small"
                                    options={agentList}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Responsible person'}
                                            sx={{ "& .MuiInputBase-root": { background: "white" } }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors.assignIds !== undefined}
                                            helperText={
                                                errors.assignIds !== undefined ? errors.assignIds.message : ""
                                            }
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item md={12}>
                    <TextSunEditor
                        name={`title`}
                        defaultValue={getValues(`title`)}
                        control={control}
                        height="150px"
                        resetValue={dataResetEditor}
                        setDataResetEditor={setDataResetEditor}
                    />
                    <input type="hidden" {...register(`title`)} />
                    {errors?.title !== undefined ? <Typography variant='body2' color={'error'}>This field is required</Typography> : ""}
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        {fields.map((_, index) => (
                            <Grid item md={3} key={index} >
                                <Grid container spacing={1}>
                                    <Grid item md={12}>
                                        <TextField
                                            sx={{ "& .MuiInputBase-root": { background: "white" } }}
                                            {...register(`documents.${index}.title`)}
                                            label={`Document Name ${index + 1}`}
                                            fullWidth
                                            size="small"
                                            placeholder='Input Documents Name'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors?.documents?.[index]?.title !== undefined}
                                            helperText={errors?.documents?.[index]?.title !== undefined ? errors?.documents?.[index]?.title?.message : ""}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <UploaderCustom
                                            page={"task-document"}
                                            widthImg={""}
                                            heightImg={""}
                                            idBtnUpload={"task-document"}
                                            onChange={(url: string) => setValue(`documents.${index}.link`, url)}
                                            documents={getValues(`documents.${index}.title`)}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Tooltip title="Remove">
                                            <IconButton color="error" onClick={() => remove(index)}><Delete /></IconButton>
                                        </Tooltip>
                                        {/* <Button onClick={() => remove(index)} color="error" size="small" variant="contained" startIcon={<Remove />}>Remove</Button> */}
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}
                        <Grid item md={2}>
                            <Button onClick={() => append({ title: '', link: '' })} color="primary" variant="outlined" startIcon={<Add />}>Add Document</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Typography variant='body2' fontWeight={'bold'}>Content Activities</Typography> */}

            <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                <LoadingButton
                    loading={loading}
                    sx={{ width: 90 }}
                    // startIcon={<Add />}
                    type='submit' variant='contained' color="primary"
                    disabled={status === "COMPLETE"}
                >
                    Save
                </LoadingButton>
            </Box>

            {/* <TextField
                {...register("title")}
                fullWidth
                size='small'
                placeholder='Things to do...'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <LoadingButton
                                loading={loading}
                                startIcon={<Add />}
                                type='submit' variant='contained' color="primary"
                                disabled={status === "COMPLETE"}
                            >
                                Add Activity
                            </LoadingButton>
                        </InputAdornment>
                    ),
                }}
                error={errors.title !== undefined}
                helperText={errors.title !== undefined ? errors.title.message : ""}
                sx={{ "& .MuiInputBase-root": { paddingRight: "4px !important" }, mb: "0  !important" }}
            /> */}
        </Box>
    )
}

export default FormActivities
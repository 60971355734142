import React, { FunctionComponent, useEffect, useState } from "react";
import { CardProps } from "../../interfaces/kanban";
import Card from '@mui/material/Card';
import { Box, CardActions, CardContent, CardHeader, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Delete, Edit, FavoriteBorder, KeyboardArrowRight, Visibility } from "@mui/icons-material";
import { lowercaseLetter } from "../../utils/function";
import ViewTask from "../../pages/home/kanban/ViewTask";
import { formatDateTimeDay } from "../../utils";
import moment from "moment";
import Swal from "sweetalert2";
import taskService from "../../services/taskService";
import ModalTask from "../../pages/home/kanban/ModalTask";
import { Link, useSearchParams } from "react-router-dom";
import { userAuth } from "../../interfaces/authentication";

const CardCustom: FunctionComponent<CardProps> = (props) => {

  const [openDrawer, setOpenDrawer] = useState(false);
  const [viewTask, setViewTask] = useState(false);
  const [idTask, setIdTask] = useState(props.data.id);
  const [params, setParams] = useSearchParams();

  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

  const handleDelete = (id: number, title: string) => {
    Swal.fire({
      icon: "question",
      title: title,
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ff5752",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        const deleteData = await taskService.deleteTask({ id: id });
        if (deleteData.status === 200) {
          props.callBack();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Delete Task Successfully !",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    });
  };

  const handleFavorite = async (id: number, status: boolean) => {
    const data = { isFavorites: !status }
    const favorites = await taskService.changeFavorite(data, id);
    if (favorites.status === 200) {
      props.callBack();
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: !status ? "Added to favorites !" : "Removed from favorites !",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  const renderPriority = (item: string) => {
    switch (item) {
      case 'LOW':
        return <Box sx={{ mr: 2, mt: 1, fontSize: 12, fontWeight: 600, background: "#a8adb4", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>{lowercaseLetter(item)}</Box>
      case 'MEDIUM':
        return <Box sx={{ mr: 2, mt: 1, fontSize: 12, fontWeight: 600, background: "#9dcf00", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>{lowercaseLetter(item)}</Box>
      case 'HIGH':
        return <Box sx={{ mr: 2, mt: 1, fontSize: 12, fontWeight: 600, background: "#ff5752", px: .5, py: .2, borderRadius: 1, color: "#fff" }}>{lowercaseLetter(item)}</Box>
      default:
        return null
    }
  }

  useEffect(() => {
    if (params.get("taskid") && Number(params.get("taskid")) === Number(props.data.id)) {
      setIdTask(Number(params.get("taskid")));
      setViewTask(true);
    }
    setParams("");
  }, [params]);


  return (
    <Card
      onMouseDown={() => props.setSelectedId(props.data)}
      variant="outlined"
      sx={{
        // border: props.data.title ? "1px solid #ff5752" : '',
        borderRadius: 2,
        transition: ".4s all",
        "&:hover": { background: "#174d7605", border: `1px solid ${props.color}` }
      }}
    >
      {props.children ? props.children :
        <Box  >
          <CardHeader
            action={
              <Box>{renderPriority(props.data.priority)}</Box>
            }
            title={<Box>{props.data.userCreateInfo && <span style={{ fontWeight: 700 }}>{props.data.userCreateInfo.firstName}</span>} {props.data.responsiblePersonInfo && props.data.userCreateInfo.firstName !== props.data.responsiblePersonInfo.firstName && <KeyboardArrowRight sx={{ fontSize: 18, position: "relative", top: "5px" }} />} {props.data.responsiblePersonInfo && props.data.userCreateInfo.firstName !== props.data.responsiblePersonInfo.firstName && `${props.data.responsiblePersonInfo?.firstName}`}</Box>}
            titleTypographyProps={{ fontSize: 14, fontWeight: 500 }}
            subheader={formatDateTimeDay(props.data.createdAt)}
            subheaderTypographyProps={{ fontSize: 13 }}
            sx={{ p: 1, borderBottom: `2px solid ${props.color}`, background: "#fff" }}
          />
          <CardContent sx={{ p: 1 }}>
            {props.data.featureAssign && props.data.linkToFeature === "Reservation" && <Link to={process.env.REACT_APP_ENV === "DEVELOPMENT" ? `https://dev-subscribers.tkgplatform.com.au/${props.data?.featureLinkType === "Booking" ? "booking" : "quotation"}/${props.data?.featureAssign}` : `https://staging-subscriber.tkgplatform.com.au/${props.data?.featureLinkType === "Booking" ? "booking" : "quotation"}/${props.data?.featureAssign}`} target="_blank"><Typography sx={{ fontSize: 13, fontWeight: 600, color: '#174d75', mb: 1 }}>{props.data?.linkToFeature} / {props.data?.featureLinkType} / {props.data?.featureAssign}</Typography></Link>}
            <Typography variant="body2" component="div" sx={{ background: "#dee2e691", borderRadius: 2, p: .5, mb: 2, overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", fontWeight: 600 }}>
              {props.data.title}
            </Typography>

            {/* <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: props.data.content }} /> */}
            <Box display={'flex'} alignContent={'center'} justifyContent={'space-between'} pt={1}>
              <Typography sx={{ fontSize: 13 }} textAlign={'right'}>
                Status: <span style={{ fontWeight: 700, color: `${props.color}` }}>{props.data.status == "INPROGRESS" ? "In Progress" : lowercaseLetter(props.data.status)}</span>
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <Tooltip title="Favorite" placement="left" onClick={() => handleFavorite(props.data.id, props.data.isFavorites)}>
                  <FavoriteBorder sx={{ color: props.data.isFavorites ? "red" : "#a8adb4", cursor: "pointer", mr: 1 }} />
                </Tooltip>

                {!(JSON.parse(props.data?.seenByIds) ?? []).find((item: number) => item == 3) &&
                  <Typography className="text-new-effect" sx={{ fontSize: 13, fontWeight: 600 }} textAlign={'right'}>New</Typography>}
              </Box>
            </Box>

            {/* {props.data.responsiblePersonInfo &&
              <Box sx={{ lineHeight: "20px", mt: .5 }}>
                <Box sx={{ color: "#000", fontSize: 12, fontWeight: 600, display: "inline-block" }}>Responsible Person</Box>
                <KeyboardArrowRight sx={{ fontSize: 18, position: "relative", top: "7px" }} />
                <Box sx={{ color: "#fff", letterSpacing: .1, fontSize: 10, fontWeight: 600, background: props.color, display: "inline-block", borderRadius: 2, px: .5, mr: .5 }}>{props.data.responsiblePersonInfo.firstName}</Box>
              </Box>} */}

            {props.data.responsiblePersonExtraInfo.length > 0 ?
              <Box sx={{ lineHeight: "20px", mt: .5 }}>
                <Box sx={{ color: "#000", fontSize: 12, fontWeight: 600, display: "inline-block", }}>Extra</Box>
                <KeyboardArrowRight sx={{ fontSize: 18, position: "relative", top: "7px" }} />

                {props.data.responsiblePersonExtraInfo && props.data.responsiblePersonExtraInfo.map((item, index) => <Box key={index} sx={{ color: "#fff", letterSpacing: .1, fontSize: 10, fontWeight: 600, background: props.color, display: "inline-block", borderRadius: 2, px: .5, mr: .5 }}>{item.firstName}</Box>)}
              </Box>
              : ""}
            {props.data.deadline != null && <Typography sx={{ fontSize: 13, mt: 1.5 }}>
              Deadline: <span style={{ fontWeight: 500, color: 'red' }}>{moment(props.data.deadline).format('ddd, DD-MMM-YYYY')}</span></Typography>}
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between", borderTop: "1px solid #f1f1f1", p: .5, background: "#fff" }}>
            <Tooltip title="Delete Task">
              <span>
                <IconButton disabled={props.data.userCreateInfo?.id != user.id || props.data.status === "INPROGRESS" || props.data.status === "PAUSE"} onClick={() => handleDelete(props.data.id, props.data.title)} aria-label="Edit icon" size="small" sx={{ color: `${props.color}`, opacity: "0.8" }}>
                  <Delete sx={{ fontSize: 20 }} />
                </IconButton>
              </span>

            </Tooltip>
            <Box>
              <Tooltip title="Edit Task">
                <IconButton aria-label="Edit icon" size="small" sx={{ color: `${props.color}`, opacity: "0.8", mr: 1 }} onClick={() => {
                  setOpenDrawer(true)
                }}>
                  <Edit sx={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Task">
                <IconButton aria-label="Edit icon" size="small" sx={{ color: `${props.color}`, opacity: "0.8" }} onClick={() => setViewTask(true)}>
                  <Visibility sx={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </CardActions>
        </Box>
        // <main className={`drag_item ${props.draggerImg ? "drag_item_drag_img" : ""}`}>
        // </main>
      }
      <ModalTask handleClose={() => setOpenDrawer(false)} isOpen={openDrawer} idDetail={props.data.id} callBack={props.callBack} />
      <ViewTask handleClose={() => setViewTask(false)} isViewTask={viewTask} idDetail={idTask} callBackList={props.callBack} />
    </Card>

  );
};

export default CardCustom;

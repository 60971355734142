import React, { useRef, useEffect } from "react";
import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css';
import { useController } from 'react-hook-form';
import { UploadBeforeHandler, UploadBeforeReturn } from "suneditor-react/dist/types/upload";

const ButtonCustomList = [
  ['undo', 'redo'],
  ['fontSize'],
  ['formatBlock'],
  ['bold', 'underline', 'italic', 'strike'],
  // ['removeFormat'],
  ['fontColor', 'hiliteColor'],
  // ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list'],
  ['link', 'table'],
  ['image'],
  ['codeView']
];

const TextSunEditor = ({ control, name, defaultValue, height = "200px", resetValue, setDataResetEditor }: any) => {
  const editor = useRef<SunEditorCore>();
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;

  };



  const {
    field: {
      value,
      ...inputProps
    }
  } = useController({
    name,
    control,
    defaultValue: defaultValue || ''
  });


  const handleImageUploadBefore = (files: Array<File>, info: object, uploadHandler: UploadBeforeHandler): any => {
    const form = new FormData();
    if (files[0]?.name) {
      form.append("image", files[0]);
      form.append("width", '');
      form.append("height", '');
      form.append("page", 'task');
      form.append("bucket", process.env.REACT_APP_BUCKET_S3 ?? "");
      form.append("bucket_folder", process.env.REACT_APP_BUCKET_FOLDER ?? "");
      form.append("company", localStorage.getItem("companyCode") ?? "");
      fetch("https://dev-file-system.tkgplatform.com.au/api/components/upload-image", {
        method: "POST",
        body: form,
        headers: process.env.REACT_APP_UPLOAD_IMAGE_LOCAL ? { "x-api-key": "clZvSRq5BYftEyNtmX2PiUIdMfkz5E3ljswW12iWEDIZtK4SSnsRzvimrXz6l04T" } : {}
      }).then((res) => {
        return res.json();
      }).then((res) => {
        const response = {
          "result": [
            {
              "url": res.response.preview,
              "name": files[0].name,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);
      })
    }
  }

  useEffect(() => {
    if (resetValue?.length > 0) {
      editor.current?.setContents("");
      setDataResetEditor("");
    }
  }, [resetValue]);

  return (
    <SunEditor
      {...inputProps}
      getSunEditorInstance={getSunEditorInstance}
      defaultValue={value}
      height={height}

      setOptions={{
        // mode:"balloon",
        buttonList: ButtonCustomList,
      }}
      onImageUploadBefore={handleImageUploadBefore}

      // setContents={value ? value : ""}
      setDefaultStyle="font-family: '__Roboto_42e952','__Roboto_Fallback_42e952';"
    />

  )
}

export default TextSunEditor
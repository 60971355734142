import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  Droppable,
  DroppableProvided
} from "react-beautiful-dnd";

type ListProps = {
  children?: React.ReactNode;
  title: string;
  onDragEnd: (data: any) => void;
  id: string;
  centerTitle?: boolean;
  color: string;
};

const List = ({ children, id, title, centerTitle, color }: ListProps) => {
  return (
    <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
      <Droppable droppableId={id} isDropDisabled={id === '1'}>
        {(provided: DroppableProvided) => (
          <Box ref={provided.innerRef} sx={{ background: "#2778c41c", height: "88vh", borderRadius: '8px' }}>
            {title ?
              <Box sx={{ background: color, borderRadius: '8px 8px 0 0' }}>
                <Typography fontWeight={700} className={`dragger_title ${centerTitle ? "center_title" : ""}`}>{title}</Typography>
              </Box>
              : ""}
            <Box className={`drag_box ${title ? "drag_box_on_drag_tittle" : ""}`} >
              {children}
              {provided.placeholder}
            </Box>
          </Box>
        )}
      </Droppable>

    </Grid>
  );
};

export default List;

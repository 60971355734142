import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
	timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import moment from 'moment';
import { Box, CircularProgress, Drawer, Grid, IconButton, MenuItem, Pagination, TextField, Typography } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import taskService from '../../../services/taskService';

interface Props {
	idTask: number;
	showHistory: boolean;
	setShowHistory: (data: boolean) => void;
}


const ModalHistory = ({ idTask, showHistory, setShowHistory }: Props) => {
	const [dataLog, setDataLog] = useState([]);
	const [loading, setLoading] = useState(false)

	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [perPage, setPerPage] = React.useState(5);

	const fetchHistory = async () => {
		try {
			setLoading(true);
			const payload = { pageNumber: page, pageSize: perPage }
			let res: any = {};
			res = await taskService.getTaskHistory(Number(idTask), payload);

			if (res?.status === 200) {
				setDataLog(res?.data?.data);
				setTotal(res?.data?.total);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error: any) {
			Swal.fire({
				icon: "error",
				width: 700,
				text: error?.response?.data?.message,
				showCloseButton: true,
			});
			setLoading(false);
		}
	}

	useEffect(() => {
		if (showHistory) {
			setDataLog([]);
			fetchHistory();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showHistory, page, perPage])

	return (
		<Drawer
			sx={{
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: { xs: '100%', sm: '100%', md: '80%', lg: '80%', xl: '75%' },
					boxSizing: 'border-box',
				},
			}}
			anchor="right"
			open={showHistory}
			onClose={() => setShowHistory(!showHistory)}
		>
			<Box
				display={'flex'}
				flexDirection={'column'}
				height={'calc(100% - 10px)'}
			>
				<Box
					sx={{
						padding: '5px 20px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: "space-between",
						borderBottom: 1,
						borderBottomColor: (theme) => theme.palette.divider,
						minHeight: { xs: 56, sm: 70 },
						position: 'fixed',
						background: '#fff',
						zIndex: 105,
						width: '-webkit-fill-available',
					}}
				>
					<Typography component="h2" className='text-20 fw-600'>
						{`Task History`}
					</Typography>

					<Box
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<IconButton
							sx={{
								height: 40,
								width: 40,
								marginLeft: '30px',
								color: 'text.secondary',
							}}
							onClick={() => setShowHistory(!showHistory)}
							size="large"
						>
							<CancelOutlined />
						</IconButton>
					</Box>

				</Box>
				<Box
					height={'calc(100% - 80px)'}
					marginTop={'70px'}
					bgcolor={'#f5f5f5'}
				>
					<Box display={'flex'} sx={{ background: '#fff' }} justifyContent={'space-between'} px={3}>

						<Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
							<TextField
								select
								value={perPage}
								onChange={(e) => { setPerPage(Number(e?.target?.value)); setPage(1); }}
								className="w-100 h-40 mt-8"
							>
								{[5, 10, 15, 20, 50, 100, 150]?.map((e: number, i: number) => (
									<MenuItem key={i} value={e}>
										{e} / Page
									</MenuItem>
								))}
							</TextField>
							<Pagination
								count={Math.ceil(total / perPage)}
								onChange={(event, value) => setPage(value)}
								page={page}
							/>
						</Box>
					</Box>
					{loading ? <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
						<CircularProgress />
					</Box></Box> : <Box height={'calc(100% - 115px)'} overflow={'scroll'}>
						<Timeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex: 0.2,
								},
								background: '#fff',
							}}
						>
							{dataLog?.length > 0 && dataLog?.map((item: any, i: number) => {
								if (item?.detail?.event && Object.keys(JSON.parse(item?.detail?.event).oldValue).find((e: any, iz: number) => JSON.parse(item?.detail?.event).oldValue[e] !== JSON.parse(item?.detail?.event)?.newValue[e]))
									return (
										<TimelineItem key={i}>
											<TimelineOppositeContent color="textSecondary">
												<Typography fontWeight={600} fontSize={14}>{moment(item?.detail?.createdAt).format('DD-MMM-YYYY, HH:mm A')}</Typography>
												<Typography fontWeight={600} fontSize={14} pt={2}>Action by <span style={{ color: '#000', fontWeight: 600 }}>{item?.user?.firstName} {item?.user?.lastName} </span></Typography>
											</TimelineOppositeContent>
											<TimelineSeparator>
												<TimelineDot variant='outlined' color='primary' />
												<TimelineConnector color='primary' />
											</TimelineSeparator>
											<TimelineContent>
												{item?.detail?.event && Object.keys(JSON.parse(item?.detail?.event).oldValue).map((e: any, iz: number) => {
													if (JSON.parse(item?.detail?.event).oldValue[e] !== JSON.parse(item?.detail?.event)?.newValue[e])
														if (e !== 'updatedBy' && e !== 'createdBy' && e !== 'id') {
															return <Grid key={iz} container sx={{ borderBottom: '1px solid #c4c4c4' }} fontSize={14}>
																<Grid item xs={12} pb={2} pt={2} fontWeight={600}>
																	Change <span style={{ color: '#0288d1', textTransform: 'capitalize' }}>"{e.toLowerCase()}"</span>
																	&nbsp;from:
																	&nbsp;<span style={{ color: 'red' }}>
																		"{
																			JSON.parse(item?.detail?.event).oldValue[e] !== undefined && JSON.parse(item?.detail?.event).oldValue[e] !== '' ?
																				`${JSON.parse(item?.detail?.event).oldValue[e]}`
																				: ""
																		}"
																	</span>
																	&nbsp;to:
																	&nbsp;<span style={e === 'description' ? { color: 'green', whiteSpace: 'break-spaces' } : { color: 'green' }}>
																		"{
																			`${JSON.parse(item?.detail?.event).newValue[e]}`
																		}"
																	</span>
																</Grid>
															</Grid>
														}
													return <></>;
												})}
											</TimelineContent>
										</TimelineItem>
									)
								return <></>
							})}
						</Timeline>
					</Box>}
				</Box>
			</Box>
		</Drawer>
	)
}

export default ModalHistory

import React, { useEffect } from 'react'
import { Box, Divider, Grid, InputAdornment, Paper, SelectChangeEvent, Stack, Typography } from '@mui/material';
import ChartCustom from '../../components/Chart/BarChartCustom';
import dayjs, { Dayjs } from 'dayjs';
import YearPickerCustom from '../../components/DatePicker/YearPickerCustom';
import taskService from '../../services/taskService';
import { userAuth } from '../../interfaces/authentication';
import { AnalyticsData } from '../../interfaces/kanban';
import BarChartCustom from '../../components/Chart/BarChartCustom';



const Analytics = () => {

  const [date, setDate] = React.useState<Dayjs | null>(dayjs());
  const [dataChart, setDataChart] = React.useState<AnalyticsData[]>([]);

  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

  const getData = async () => {
    if (date) {
      const payload = {
        userId: user.id,
        type: 'monthly',
        date: date.format('YYYY-MM-DD').toString()
      }
      try {
        const res = await taskService.getAnaLyticsTask(payload);
        if (res.status === 200) {
          setDataChart(res.data)
        }
      } catch (error) {
        console.log(error);
        
      }

    }
  }

  useEffect(() => {
    getData();
    document.title = "Analytics";
  }, [date]);


  // console.log(dataChart.map(item => item.data));
  // item.data.forEach((num: number) => {
  //   sum += num;
  // })
  let sum = 0;

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
          <Box display={'flex'} justifyContent={"space-between"} mb={3}>
            <Box>
              <Box sx={{ fontWeight: 600, fontSize: 18, color: "#174d75", mb: 1 }}>TOTAL TASKS </Box>

              <Stack direction={'row'} spacing={2} >
                {dataChart.length > 0 && dataChart.map((item, index) =>
                  <Box key={index}>
                    {item.label === "Pending" && <Typography variant='body1' fontWeight={500} >Pending: {item.data.reduce((partialSum, a) => partialSum + a, 0)}</Typography>}
                    {item.label === "In Progress" && <Typography variant='body1' fontWeight={500}>In Progress: {item.data.reduce((partialSum, a) => partialSum + a, 0)}</Typography>}
                    {item.label === "Complete" && <Typography variant='body1' fontWeight={500}>Complete: {item.data.reduce((partialSum, a) => partialSum + a, 0)}</Typography>}
                  </Box>
                )}
              </Stack>
            </Box>
            <YearPickerCustom value={date} setValue={setDate} />
          </Box>
          <Box sx={{borderBottom: "1px solid #e3e3e39e", mb:3}} />

          <BarChartCustom data={dataChart} />
        </Paper>
      </Grid>
      {/* <Grid item md={6}>
        <Paper sx={{ p: 2, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
          123
        </Paper>
      </Grid> */}
    </Grid>
  )
}

export default Analytics
import dayjs, { Dayjs } from 'dayjs';
import React from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface Props {
    value: Dayjs | null
    setValue: (date: Dayjs | null) => void;
}

const YearPickerCustom = ({value, setValue} :Props) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={{ width: "140px" }}
                slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true } } }}
                defaultValue={dayjs()}
                views={['year']}
                label="Select Year"
                openTo="year"
                minDate={dayjs('01-01-2010')}
                onChange={(newValue) => setValue(newValue)}
            />
        </LocalizationProvider>
    )
}

export default YearPickerCustom
import { createBrowserRouter } from "react-router-dom";
import MainSiteLayout from "../layouts/MainSiteLayout";
import TKGTreeView from "../layouts/menu";

import HomePage from "../pages/home/HomePage";
import UserProfile from "../pages/profile/UserProfile";
import Analytics from "../pages/analytics/Analytics";

// @ts-ignore
const router = createBrowserRouter(
    [
        {
            path: "/",
            element:<MainSiteLayout memuList={<TKGTreeView />} />,
            children: [
                {
                    path: "/",
                    element: <HomePage />
                },
                {
                    path: "/profile/:id",
                    element: <UserProfile />
                },
                {
                    path: "/analytics",
                    element: <Analytics />
                },
            ],
        },


    ]
)

export default router;

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../media/images/logo-tkg.png";
import userService from "../services/userService";
import { useUrlSearchParams } from "use-url-search-params";
import { responseSendSignInCode, userAuth } from "../interfaces/authentication";
import { useGlobalContext } from "../context/MyGlobalContext";
import { ExpandMore, Language } from "@mui/icons-material";

const drawerWidth = 0;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingBottom: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  textAlign: "center",
}));

const MainSiteLayout = ({ memuList }: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(true);
  const { setUserList } = useGlobalContext();

  const [params] = useUrlSearchParams();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const token = localStorage.getItem("accessToken");

  const getCurrentUser = async () => {
    
    try {
      let [responseUser, responseListUser, resMasterInfo] = await Promise.all([
        userService.getCurrent(),
        userService.getSimple(),
        userService.getMasterInfo()
      ]);

      setUserList(responseListUser.data);
      localStorage.setItem('companyLogo', JSON.stringify(resMasterInfo.data.find((item: any) => item.key === 'companyLogo')));
      // const response = await userService.getCurrent();
      let authData: responseSendSignInCode = responseUser.data;
      localStorage.setItem("user", JSON.stringify(authData));
      setIsLoading(false);
      navigate("/");



    } catch (e: any) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("timeZone");
      localStorage.removeItem("companyLogo");
      navigate("/");
    }
  }

  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);
  const dataLogo = JSON.parse(localStorage.getItem('companyLogo') as string);

  

  React.useEffect(() => {
    if (params?.token && params?.companyCode) {
      localStorage.setItem('accessToken', `${params?.token}`);
      localStorage.setItem('companyCode', `${params?.companyCode}`);
      localStorage.setItem('timeZone', `${params?.timeZone}`);
      getCurrentUser();
    }
  }, [params]);

  React.useEffect(() => {
    if (token) {
      setIsLoading(false);
    }
  }, [token]);



  return (
    <Box sx={{ display: "flex" }}>
      {isLoading ? (
        <Backdrop
          sx={{
            color: "#fff",
            background: "white",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
          onClick={handleClose}
        >
          <CircularProgress style={{ color: "black" }} />
        </Backdrop>
      ) : (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{
              background:
                "linear-gradient(90deg, rgba(13,89,133,1) 23%, rgba(13,89,133,1) 63%, rgba(255,255,255,1) 90%)",
              color: "#6b7280",
              fontSize: 16,
              fontWeight: 500,
              boxShadow: "unset",
              borderBottom: "1px solid #0000001f",
            }}
          >
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
            >
              <Link to={
                process.env.REACT_APP_TYPE === "development" ? process.env.REACT_APP_DEVELOPMENT_HOME ?? "/" : process.env.REACT_APP_STAGING_HOME ?? "/"
              }>
                <img
                  src={Logo}
                  alt=""
                  style={{ width: "200px" }}
                />
              </Link>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {!dataLogo ? (
                  <span style={{ fontSize: "20px", display: "block" }}>
                    Your Logo Here
                  </span>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${dataLogo?.value}`}
                    alt=""
                    style={{ maxWidth: "135px", maxHeight: "38px" }}
                  />
                )}
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1, backgroundColor: "white" }}
                />
                <Box
                  onClick={handleClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    boxShadow: "unset",
                    color: "#000",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#ff9800", marginRight: "10px" }}>
                    {!user.avatarUrl ? <span>
                      {user?.firstName.charAt(0)}
                      {user?.lastName.charAt(0)}
                    </span> :
                      <img
                        width={300}
                        height={300}
                        src={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${user.avatarUrl}`}
                        alt="avatar"
                        className="img-ratio rounded-4"
                        style={{ objectFit: "cover", overflow: "hidden" }}
                      />}
                  </Avatar>
                  {user.firstName} {user.lastName}
                </Box>
              </Box>
            </Toolbar>
          </AppBar>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => navigate(`/profile/${user.id}`)}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
          </Menu>
          <Main open={open} style={{ height: "100%" }}>
            <DrawerHeader />
            {memuList}
            <Box component={"div"} sx={{ height: "100%" }}>
              <Outlet />
            </Box>
            <Box
              sx={{
                mt: 5,
                bgcolor: "#f5f5f5",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "90px",
                textAlign: "center",
                py: 2,
                px: 4,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 5 }}>
                <Box>
                  <Typography
                    color="#0d5982"
                    variant="subtitle1"
                    fontSize={"16px"}
                    fontWeight={600}
                  >
                    {`Help Desk: +61 8 7082 5321`}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    spacing={"5px"}
                    mr={"25px"}
                  >
                    <Typography
                      color="#0d5982"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`User Guide`}
                    </Typography>
                    <Typography
                      color="#0d5982"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`|`}
                    </Typography>
                    <Typography
                      color="#0d5982"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`FAQs`}
                    </Typography>
                    <Typography
                      color="#0d5982"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`|`}
                    </Typography>
                    <Typography
                      color="#0d5982"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`Support`}
                    </Typography>
                  </Stack>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Language sx={{ mr: "5px", color: "#0d5982" }} />
                    <Typography
                      color="#0d5982"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`English`}
                    </Typography>
                    <ExpandMore sx={{ ml: "5px", color: "#0d5982" }} />
                  </Box>
                </Box>
              </Box>
              <Typography color="#0d5982" variant="subtitle1" fontSize={"14px"}>
                {`© ${new Date().getFullYear()} - TKG Platform Technology`}
              </Typography>
            </Box>
          </Main>

        </>
      )}
    </Box>
  );
};

export default MainSiteLayout;

import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Collapse, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import data from '../../../mockdata/data';
import SelectReservation from '../../../components/ModalSelect/SelectReservation';
import SelectProducts from '../../../components/ModalSelect/SelectProducts';
import { ReservationListItem } from '../../../interfaces/reservation';
import { ReservationPackageProductListItemSimpleExplain } from '../../../interfaces/reservationDataInput';
import * as yup from "yup";
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HandleTask, UserItem } from '../../../interfaces/kanban';
import { getValueString } from '../../../utils';
import taskService from '../../../services/taskService';
import { userAuth } from '../../../interfaces/authentication';
import Swal from 'sweetalert2';
import userService from '../../../services/userService';
import SelectCRM from '../../../components/ModalSelect/SelectCRM';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { Add, Delete, Remove, RestartAlt, SelectAll } from '@mui/icons-material';
import TextSunEditor from '../../../components/TextSunEditor/TextSunEditor';
import UploaderCustom from '../../../components/Upload/UploaderCustom';
import validationMessage from '../../../constants/validationMessage';

interface Props {
    idDetail?: number;
    handleClose: () => void;
    callBack: () => void;
}
const validationSchema = yup.object().shape({
    title: yup.string().required("Please Enter Title").max(180, 'Must be exactly 180 characters'),
    priority: yup.string().required("Please select priority"),
    deadline: yup.string().when(['isDeadline', 'planEnd'], {
        is: (isDeadline: boolean, planEnd: string) => {
            return isDeadline && planEnd
        },
        then: (schema) => schema.required("This field is required "),
    }).nullable(),
    documents: yup.array(yup.object({
        title: yup.string().required(validationMessage.required),
        link: yup.string().required(validationMessage.required),
    })),
});

const FormTask = ({ idDetail, handleClose, callBack }: Props) => {

    const [isOpenReservation, setIsOpenReservation] = useState(false);
    const [isOpenProduct, setIsOpenProduct] = useState(false);
    const [isOpenCRM, setIsOpenCRM] = useState(false);
    const [typeReservation, setTypeReservation] = useState('');
    const [typeProduct, setTypeProduct] = useState('');
    const [typeCRM, setTypeCRM] = useState('');
    const [loadingData, setloadingData] = useState(false);
    const [agentList, setAgentList] = useState<{ label: string; value: number }[]>([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

    const handleSelectReservation = (data: ReservationListItem) => {
        setIsOpenReservation(false);
        setValue('featureAssign', data.reference)
    }
    const handleSelectProduct = (data: ReservationPackageProductListItemSimpleExplain) => {
        setIsOpenProduct(false);
        setValue('featureAssign', String(data.id))
    }
    const handleSelectCRM = (data: any) => {
        setIsOpenCRM(false);
        setValue('featureAssign', data.id)
    }


    const {
        control,
        handleSubmit,
        setValue,
        register,
        getValues,
        watch,
        reset,
        formState: { errors }
    } = useForm<HandleTask>({
        defaultValues: {
            canChangeDeadline: false,
            isFavorites: false,
            isDeadline: false,
            isKpi: false,
            status: "PENDING",
            content: '',
            responsiblePersonId: user.id,
            responsiblePersonExtraIds: [],
            userApproveId: 0,
            planEnd: "",
            planStart: "",
            featureAssign: ""

        },
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "documents",
    });

    

    const submit: SubmitHandler<HandleTask> = async (data) => {
        const documents = data.documents ? JSON.stringify(data.documents) : "";
        const convertResponsiblePersonExtra = data.responsiblePersonExtraIds ? data.responsiblePersonExtraIds.map(String) : [];
        const planDeadline = endDate ? endDate : data.deadline;

        try {
            if (idDetail) {
                await taskService.updateTask({
                    ...data,
                    responsiblePersonExtraIds: convertResponsiblePersonExtra,
                    deadline: planDeadline,
                    documents: documents
                }, idDetail).then(res => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your Task has been saved !",
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            } else {
                await taskService.createTask({ ...data, documents: documents });
                if (moment(data.deadline).startOf('isoWeek').isAfter(moment().add(2, 'weeks'), "week")) {
                    Swal.fire({
                        icon: "success",
                        title: "Create task successfully. Please review it in Upcoming Tasks !",
                    });
                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Create Task Successfully !",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }

            }
        } catch (e: any) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Something not wrong !",
                showConfirmButton: false,
                timer: 1500
            });
        }
        handleClose();
        callBack();
    }

    const getDetails = async () => {
        let [detaiData, resAgentList] = await Promise.all([
            taskService.getDetailById({ id: idDetail }),
            userService.getSimple()
        ]);
        if (resAgentList.status === 200) {
            setAgentList(resAgentList.data.map((item: UserItem) => ({
                label: item.firstName + ' ' + item.lastName,
                value: item.id
            })));
        }
        if (detaiData.status === 200) {
            reset({
                ...detaiData.data,
                responsiblePersonExtraIds: JSON.parse(detaiData.data.responsiblePersonExtraIds).map(Number),
                documents: JSON.parse(detaiData.data.documents)
            });
        }
        setloadingData(false)
    };

    const getListUser = async () => {
        const userL = await userService.getSimple();
        if (userL.status === 200) {
            setAgentList(userL.data.map((item: UserItem) => ({
                label: item.firstName + ' ' + item.lastName,
                value: item.id
            })));
            setloadingData(false)
        }
    }

    const getListData = (type: string) => {
        switch (type) {
            case "CRM":
                return data.crm
            case "Reservation":
                return data.reservation
            case "Products":
                return data.product
            default:
                return [];
        }
    }

    useEffect(() => {
        if (idDetail) {
            getDetails();
        } else {
            getListUser();
        }
        setloadingData(true);
    }, [idDetail]);

    return (
        <form id={`form-handle-task`} onSubmit={handleSubmit(submit)}>
            {loadingData ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
                <CircularProgress />
            </Box> : <Grid container spacing={2} >
                <Grid item md={12} >
                    <TextField
                        {...register("title")}
                        label={'Title (*)'}
                        fullWidth
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={errors.title !== undefined}
                        helperText={errors.title !== undefined ? errors.title.message : ""}
                    />
                </Grid>
                <Grid item md={12} mb={2}>
                    <Typography variant='body2' fontWeight={600} fontSize={12}>Content</Typography>
                    <TextSunEditor
                        name={`content`}
                        defaultValue={getValues(`content`)}
                        control={control}
                        height="350px"
                    />
                    <input type="hidden" {...register(`content`)} />
                    {errors?.content !== undefined ? <Typography variant='body2' color={'error'}>This field is required</Typography> : ""}
                </Grid>
                {/* <Grid item md={12} mb={2}>
                    <Controller
                        control={control}
                        render={({ field: { onChange } }) => {
                            return (
                                <Box className={errors.content !== undefined ? "editor-validate" : ""}>
                                    <Editor
                                        value={getValueString(getValues("content"))}
                                        apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                                        init={{ ...configEditor.editorAttr, height: 300 }}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        onEditorChange={onChange}
                                    />
                                </Box>
                            );
                        }}
                        name={"content"}
                    />
                </Grid> */}
                <Grid item md={3}>
                    <Controller
                        control={control}
                        name="responsiblePersonId"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    disablePortal
                                    id="responsiblePersonId"
                                    defaultValue={agentList.find(item => item.value === Number(value))}
                                    onChange={(event, value) => {
                                        onChange(value?.value);
                                    }}
                                    size="small"
                                    options={agentList}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Responsible person'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors.responsiblePersonId !== undefined}
                                            helperText={
                                                errors.responsiblePersonId !== undefined ? errors.responsiblePersonId.message : ""
                                            }
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item md={6}>
                    <Controller
                        control={control}
                        name="responsiblePersonExtraIds"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="responsiblePersonExtraIds"
                                    multiple
                                    defaultValue={(agentList ?? []).filter(item => value?.includes(item.value))}
                                    onChange={(event, value) => {
                                        onChange(value.map(item => item.value));
                                    }}
                                    size="small"
                                    options={agentList.filter(item => item.value != watch('responsiblePersonId'))}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Responsible person extra'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors.responsiblePersonExtraIds !== undefined}
                                            helperText={
                                                errors.responsiblePersonExtraIds !== undefined ? errors.responsiblePersonExtraIds.message : ""
                                            }
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        control={control}
                        name="userApproveId"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    disablePortal
                                    id="userApproveId"
                                    defaultValue={agentList.find(item => item.value === value)}
                                    onChange={(event, value) => {
                                        onChange(value?.value);
                                    }}
                                    size="small"
                                    options={agentList}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Approve Task'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    background: "#d6eaff"
                                                }
                                            }}
                                            error={errors.userApproveId !== undefined}
                                            helperText={
                                                errors.userApproveId !== undefined ? errors.userApproveId.message : ""
                                            }
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        control={control}
                        name="deadline"
                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled={watch('isDeadline')}
                                        label="Deadline (*)"
                                        slotProps={{
                                            textField: {
                                                size: 'small', InputLabelProps: { shrink: true }, error: !!error,
                                                helperText: error?.message,
                                            },
                                            actionBar: {
                                                actions: ['clear', 'accept'],
                                            },
                                        }}
                                        sx={{ width: "100%" }}
                                        disablePast
                                        format="DD/MM/YYYY"
                                        value={value ? dayjs(value) : null}
                                        onChange={(value: Dayjs | null) => {
                                            if (value) {
                                                onChange(value.format("YYYY-MM-DD"));
                                            } else {
                                                onChange(null);
                                            }
                                        }}
                                        views={['year', 'month', 'day']}
                                    />
                                </LocalizationProvider>
                            );
                        }}
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        control={control}
                        name="priority"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="priority"
                                    defaultValue={value}
                                    onChange={(event, value) => {
                                        onChange(value);
                                    }}
                                    size="small"
                                    options={data.priority}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Priority (*)'}
                                            placeholder='Select priority'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors.priority !== undefined}
                                            helperText={
                                                errors.priority !== undefined ? errors.priority.message : ""
                                            }
                                        />
                                    )}
                                />
                            );
                        }}
                    />

                </Grid>

                <Grid item md={6} >
                    <FormGroup sx={{ float: 'right' }}>
                        <Controller
                            control={control}
                            name="canChangeDeadline"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormControlLabel control={<Checkbox disabled={idDetail && watch('createdBy') != user.id ? true : false} checked={value} onChange={(e, checked) => onChange(checked)} />} label={<Typography fontSize={16}>Responsible person can change deadline</Typography>} />
                                )
                            }}
                        />

                    </FormGroup>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Controller
                                control={control}
                                name="linkToFeature"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="linkToFeature"
                                            defaultValue={data.linkToFeature.find(item => item === value)}
                                            onChange={(event, value) => {
                                                onChange(value);
                                            }}
                                            size="small"
                                            options={data.linkToFeature}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Link to Item'}
                                                    placeholder='Link to Item'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={errors.linkToFeature !== undefined}
                                                    helperText={
                                                        errors.linkToFeature !== undefined ? errors.linkToFeature.message : ""
                                                    }
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />
                        </Grid>

                        {watch("linkToFeature") && <Grid item md={3}>
                            <Controller
                                control={control}
                                name="featureLinkType"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="featureLinkType"
                                            defaultValue={getListData(watch("linkToFeature") ?? "").find((item: any) => item === value)}
                                            onChange={(_, value) => {
                                                onChange(value);
                                                switch (value) {
                                                    case 'Booking':
                                                        setTypeReservation('booking');
                                                        setIsOpenReservation(true);
                                                        break;
                                                    case 'Quotation':
                                                        setTypeReservation('quotation');
                                                        setIsOpenReservation(true);
                                                        break;
                                                    case 'Tour':
                                                        setTypeProduct('tour')
                                                        setIsOpenProduct(true);
                                                        break;
                                                    case 'Golf':
                                                        setTypeProduct('golf')
                                                        setIsOpenProduct(true)
                                                        break;
                                                    case 'Contact':
                                                        setTypeCRM('Contact');
                                                        setIsOpenCRM(true);
                                                        break;
                                                    case 'Company':
                                                        setTypeCRM('Company');
                                                        setIsOpenCRM(true);
                                                        break;
                                                    default:
                                                        break;
                                                }

                                            }}
                                            size="small"
                                            options={getListData(watch("linkToFeature") ?? "")}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Item Type'}
                                                    placeholder='Item Type'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={errors.featureLinkType !== undefined}
                                                    helperText={
                                                        errors.featureLinkType !== undefined ? errors.featureLinkType.message : ""
                                                    }
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />
                        </Grid>}
                        {watch("featureLinkType") && <Grid item md={6}>
                            <TextField
                                {...register("featureAssign")}
                                label={`Item ${watch("linkToFeature") ?? ''}`}
                                fullWidth
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onFocus={() => {
                                    switch (watch('featureLinkType')) {
                                        case 'Booking':
                                            setTypeReservation('booking');
                                            setIsOpenReservation(true);
                                            break;
                                        case 'Quotation':
                                            setTypeReservation('quotation');
                                            setIsOpenReservation(true);
                                            break;
                                        case 'Tour':
                                            setTypeProduct('tour')
                                            setIsOpenProduct(true);
                                            break;
                                        case 'Golf':
                                            setTypeProduct('golf')
                                            setIsOpenProduct(true)
                                            break;
                                        case 'Contact':
                                            setTypeCRM('Contact');
                                            setIsOpenCRM(true);
                                            break;
                                        case 'Company':
                                            setTypeCRM('Company');
                                            setIsOpenCRM(true);
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                                error={errors.featureAssign !== undefined}
                                helperText={errors.featureAssign !== undefined ? errors.featureAssign.message : ""}
                            />
                        </Grid>}

                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={2}>
                        {fields.map((_, index) => (
                            <Grid item md={3} key={index} >
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <TextField
                                            {...register(`documents.${index}.title`)}
                                            label={`Document Name ${index + 1}`}
                                            fullWidth
                                            size="small"
                                            placeholder='Input Documents Name'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors?.documents?.[index]?.title !== undefined}
                                            helperText={errors?.documents?.[index]?.title !== undefined ? errors?.documents?.[index]?.title?.message : ""}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <UploaderCustom
                                            page={"task-document"}
                                            widthImg={""}
                                            heightImg={""}
                                            idBtnUpload={"task-document"}
                                            onChange={(url: string) => setValue(`documents.${index}.link`, url)}
                                            documents={getValues(`documents.${index}.title`)}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <IconButton onClick={() => remove(index)} color="error" ><Delete /></IconButton>
                                        {/* <Button onClick={() => remove(index)} color="error" size="small" variant="contained" ><Delete /></Button> */}
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}
                        <Grid item md={2}>
                            <Button onClick={() => append({ title: '', link: '' })} color="primary" variant="outlined" startIcon={<Add />}>Add Document</Button>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item md={6}>
                      <TextField
                        size="small"
                        className="pb-8"
                        fullWidth
                        label={"Document Name (*)"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={inputName}
                        onChange={(e: any) => setInputName(e.target.value)}
                        disabled={!isEdit}
                      />
                      <UploaderCustom
                        documents={documents}
                        setDocuments={setDocuments}
                        page={"crm-contact"}
                        widthImg={""}
                        heightImg={""}
                        idBtnUpload={"contact-document"}
                        isEdit={isEdit}
                      />{" "}
                      <Box display={"flex"} justifyContent={"right"}>
                        <Button
                          variant="contained"
                          className="px-16 py-8 text-13"
                          component="label"
                          disabled={!isEdit || disabledBtn}
                          onClick={() => handleSave()}
                        >
                          Save
                        </Button>
                      </Box>
                </Grid> */}

                <Grid item md={5} xs={12}>
                    <Box sx={{ background: "#eee", borderRadius: 3, px: 2, pt: 2, pb: 1 }}>
                        <Typography sx={{ mb: 2, fontWeight: 600 }}>Time Planning</Typography>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <Box>
                                    <input type="hidden" {...register("planStart")} />
                                    <input type="hidden" {...register("planEnd")} />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateRangePicker
                                            localeText={{ start: 'Start task on', end: 'Finish' }}
                                            slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, sx: { background: "#fff", borderRadius: 2, width: "100%" } } }}
                                            className={"date-range"}
                                            disablePast
                                            value={[dayjs(getValues('planStart')), dayjs(getValues('planEnd'))]}
                                            onChange={(newValue) => {
                                                if (newValue[1] != undefined) setValue("deadline", newValue[1] ? newValue[1].format("YYYY-MM-DD") : "")
                                                setValue("planStart", newValue[0] ? newValue[0].format("YYYY-MM-DD") : "");
                                                setValue("planEnd", newValue[1] ? newValue[1].format("YYYY-MM-DD") : "");
                                                setStartDate(newValue[0] ? newValue[0].format("YYYY-MM-DD") : "");
                                                setEndDate(newValue[1] ? newValue[1].format("YYYY-MM-DD") : "")
                                            }}
                                            format={"DD/MM/YYYY"}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
                <Grid item md={7} xs={12}>
                    <Box sx={{ background: "#eee", borderRadius: 3, px: 2, pt: 2, pb: 1 }}>
                        <Typography sx={{ mb: 2, fontWeight: 600 }}>Option</Typography>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <Controller
                                    control={control}
                                    name="isKpi"
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <FormControlLabel control={<Checkbox checked={value} onChange={(e, checked) => onChange(checked)} />} label="KPI Task" />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Controller
                                    control={control}
                                    name="isDeadline"
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <FormControlLabel control={<Checkbox checked={value} disabled={watch('deadline') ? true : false} onChange={(e, checked) => onChange(checked)} />} label="No Deadline" />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Controller
                                    control={control}
                                    name="reminderAt"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Reminder Date"
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small', InputLabelProps: { shrink: true }, error: !!error,
                                                            helperText: error?.message,
                                                        },
                                                        actionBar: {
                                                            actions: ['clear', 'accept'],
                                                        },
                                                    }}
                                                    sx={{ background: "#fff", borderRadius: 2, width: 230, textAlign: "center" }}
                                                    disablePast
                                                    format="DD/MM/YYYY"
                                                    value={value ? dayjs(value) : null}
                                                    onChange={(value: Dayjs | null) => {
                                                        if (value) {
                                                            onChange(value.format("YYYY-MM-DD"));
                                                        } else {
                                                            onChange(null);
                                                        }
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                />
                                            </LocalizationProvider>

                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>}

            <SelectReservation type={typeReservation} isOpen={isOpenReservation} handleClose={() => setIsOpenReservation(false)} handleSelect={handleSelectReservation} />
            <SelectProducts type={typeProduct} isOpen={isOpenProduct} handleClose={() => setIsOpenProduct(false)} handleSelect={handleSelectProduct} />
            <SelectCRM type={typeCRM} isOpen={isOpenCRM} handleClose={() => setIsOpenCRM(false)} handleSelect={handleSelectCRM} />

        </form>
    )
}

export default FormTask

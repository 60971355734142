import { Close } from '@mui/icons-material'
import { DialogContent, DialogTitle, Drawer, IconButton } from '@mui/material'
import React from 'react'

type DrawerProps = {
    children?: React.ReactNode;
    title: string;
    openDrawer: boolean;
    handleClose: () => void;
};

const DrawerCustom = ({ children, title, openDrawer, handleClose }: DrawerProps) => {
    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: { xs: '100%', sm: '100%', md: '90%', lg: '90%', xl: '85%' },
                    boxSizing: 'border-box',
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    m: 0,
                    p: 2,
                    background: "#174D75",
                    color: "#fff"
                }}
            >
                {title}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ color: "#fff" }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            {children}

        </Drawer>
    )
}

export default DrawerCustom
import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AddAPhoto, AddToPhotos, AddToPhotosOutlined, CloudUpload, UploadFile } from "@mui/icons-material";



interface DocumentUploadInterface {
  documents: string | null;
  // setDocuments: any;
  onChange: (data: any) => void;
  page: string;
  widthImg: number | string;
  heightImg: number | string;
  idBtnUpload: string;
  isEdit?: boolean;
  type?: string
}
const UploaderCustom = ({
  page,
  widthImg,
  heightImg,
  documents,
  // setDocuments,
  onChange,
  idBtnUpload,
  isEdit = true,
  type
}: DocumentUploadInterface) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const [urlName, setUrlName] = useState("");

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const reader: any = new FileReader();

    if (!file) {
      Swal.fire({
        icon: "error",
        text: "Please select an document",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    if ((file && file.type.includes("image")) && (type && type === "pdf")) {
      Swal.fire({
        icon: "error",
        text: "Please select an document",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    } else if (file && file.type.includes("application") && (type && type === "image")) {
      Swal.fire({
        icon: "error",
        text: "Please select an image",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }
    if (
      file.size / 1024 >
      Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE)
    ) {
      Swal.fire({
        icon: "error",
        text:
          "Max file size is " +
          Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE) / 1024 +
          "MB!",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    reader.onload = async () => {
      setLoading(true);
      const form = new FormData();
      if (file) {
        form.append("file", file);
        form.append("image", file);
        form.append("width", '');
        form.append("height", '');
        form.append("page", page);
        form.append("bucket", process.env.REACT_APP_BUCKET_S3 ?? "");
        form.append("bucket_folder", process.env.REACT_APP_BUCKET_FOLDER ?? "");
        form.append("company", localStorage.getItem("companyCode") ?? "");
        fetch("https://dev-file-system.tkgplatform.com.au/api/components/upload-image", {
          method: "POST",
          body: form,
          headers: process.env.REACT_APP_UPLOAD_IMAGE_LOCAL ? { "x-api-key": "clZvSRq5BYftEyNtmX2PiUIdMfkz5E3ljswW12iWEDIZtK4SSnsRzvimrXz6l04T" } : {}
        }).then((res) => {
          return res.json();
        }).then((res) => {
          // console.log(res);
          if (res.meta.status === 200) {
            setUrlFile(res.response.preview);
            setUrlName(res.response.name)
            Swal.fire({
              icon: "success",
              text: "Upload document success!",
              timer: 2000,
              showConfirmButton: false,
              showCancelButton: false,
            });
          }
        });
      }
      setLoading(false);
    };
    reader.readAsDataURL(file);
  };

  const VisuallyHiddenInput = styled("input")({
    height: "100%",
    position: "absolute",
    width: "100%",
    cursor: "pointer",
    top: 0,
    right: 0,
    zIndex: 99,
    fontSize: "13px",
    opacity: 0,
  });

  useEffect(() => {
    if (urlFile) {
      onChange(urlFile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFile]);

  // console.log(documents);
  
  useEffect(() => {
    if (!documents) {
      setUrlFile("");
    }

  }, [documents]);

  return (
    <Box
      display={"flex"}
      justifyContent={(documents || urlFile) ? "start" : "center"}
      alignItems={"center"}
      flexDirection={(documents || urlFile) ? "row" : "column"}
      // height={(urlFile && urlFile.length > 0) ? "300px" : "200px"}
      border={"1px dashed #d9d9d9"}
      borderRadius={"8px"}
      sx={{ background: "#f5f5f5" }}
    >
      {documents ? 
      <Stack alignItems={"center"} direction={"row"} spacing={1} p={1}>
        <UploadFile sx={{ fontSize: "30px", color: "#174d75" }} />
        <Box component={"a"} href={documents} target="_blank" sx={{ textDecoration: "none", color: "#174d75", fontSize: 14 }}>{documents}</Box>
      </Stack> : 
      (urlFile && urlFile.length > 0) ? <Stack alignItems={"center"} direction={"row"} spacing={1} p={1}>
        <UploadFile sx={{ fontSize: "30px", color: "#174d75" }} />
        <Box component={"a"} href={urlFile} target="_blank" sx={{ textDecoration: "none", color: "#174d75", fontSize: 14 }}>{urlName}</Box>
      </Stack> :
        <Stack
          className="w-full h-full"
          position={"relative"}
          minWidth={"64px"}
          fontSize={"0.75rem"}
          color={"#5b5b5b"}
          borderRadius={"8px"}
          overflow={"hidden"}
          mx={0}
          py={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {loading ? (
            <CircularProgress />
          ) : <Box className="col-auto" style={{ paddingLeft: "10px" }}>
            <Box mt={0}>
              <label htmlFor={idBtnUpload} className="text-15 d-flex text-center" >
                <Box>
                  <AddToPhotos fontSize="large" />
                  <br />
                  <Typography sx={{ fontWeight: 600, mb: 0, color: "#000" }}>Add document</Typography>
                  <Typography sx={{ fontWeight: 400, mb: 0, fontSize: "13px", color: "#000" }}>Drag & Drop</Typography>
                </Box>

              </label>
              <VisuallyHiddenInput
                type="file"
                id={idBtnUpload}
                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
                onChange={handleImageChange}
                className="file-input"
                disabled={!isEdit}
              />
            </Box>


          </Box>}
        </Stack>
      }

      {error && !success && (
        <Box className="mt-8" color={"red"}>
          {error}
        </Box>
      )}
    </Box>
  );
};

export default UploaderCustom;



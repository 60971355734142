import apiInstance from "../config/apiInstance";
import {httpMultipartInstance} from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { CategoryType, PricingEditRequestInteface, PricingManageInterface, UpdateMultiPricingRequest, requestProductPost } from "../interfaces/product";
import { buildQueryString } from "../utils";

const productService = {
  getListCountries: async () => {
    return await apiInstance.get(endPointAPI.PRODUCT.GET_LIST_COUNTRIES);
  },
  getListDestinationsByCountries: async (payload: {
    countriesId: (number | undefined)[];
  }) => {
    return await apiInstance.post(
      endPointAPI.PRODUCT.GET_DESTINATIONS_BY_COUNTRIES,
      payload
    );
  },

  //   tour
  getList: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST}?${buildQueryString(payload)}`
    );
  },
  getSimpleList: async (type: 'golf' | 'tour') => {
    let endPoint = '';
    if ( type == "golf") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_GOLF}`;
    if ( type == "tour") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_TOUR}`;
    try {
      let response = await apiInstance.get(endPoint);
      return response.data;
    } catch (e){
      return [];
    }

  },

  getDetailProduct: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL}/${code}?language=${language}`
    );
  },

  createProductPost: async (payload: requestProductPost) => {
    return await apiInstance.post(endPointAPI.PRODUCT.CREATE, payload);
  },
  updateProductPost: async (payload: requestProductPost, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE}/${id}`,
      payload
    );
  },
  checkTripCode: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED}/${code}`
    );
  },
  deleteProductPost: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE}/${id}`);
  },

  // tour price
  getListTourPrice: async (tourId: number, payload: any) => {
    const query = buildQueryString(payload)
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_TOUR_PRICE}/${tourId}?${query}`
    );
  },
  getAvailableTourPrice: async (tourId: number) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_AVAILABLE_TOUR_PRICE}/${tourId}`
    );
  },
  getDetailTourPrice: async (id: number) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_TOUR_PRICE}/${id}`
    );
  },
  createTourPrice: async (payload: PricingManageInterface, tourId: number) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_TOUR_PRICE}/${tourId}`, payload);
  },
  importTourPrice: async (payload: FormData,tourId: number) => {
    return await httpMultipartInstance.post(`${endPointAPI.PRODUCT.IMPORT_TOUR_PRICE}/${tourId}`, payload);
  },
  updateTourPrice: async (payload: PricingEditRequestInteface, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_TOUR_PRICE}/${id}`,
      payload
    );
  },
  updateMultiTourPrice: async (payload: UpdateMultiPricingRequest) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_TOUR_MULTI_PRICE}`,
      payload
    );
  },
  deleteTourPrice: async (payload: {ids: number[]}) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_TOUR_PRICE}`, payload);
  },


  //   golf
  getListGolf: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_GOLF}?${buildQueryString(payload)}`
    );
  },
  getDetailProductGolf: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_GOLF}/${code}?language=${language}`
    );
  },

  createProductPostGolf: async (payload: requestProductPost) => {
    return await apiInstance.post(endPointAPI.PRODUCT.CREATE_GOLF, payload);
  },
  updateProductPostGolf: async (payload: requestProductPost, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_GOLF}/${id}`,
      payload
    );
  },
  checkTripCodeGolf: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED_GOLF}/${code}`
    );
  },
  deleteProductPostGolf: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_GOLF}/${id}`);
  },


    // golf price
    getListGolfPrice: async (golfPackageId: number, payload: any) => {
      const query = buildQueryString(payload)
      return await apiInstance.get(
        `${endPointAPI.PRODUCT.GET_LIST_GOLF_PRICE}/${golfPackageId}?${query}`
      );
    },
    getDetaitGolfPrice: async (id: number) => {
      return await apiInstance.get(
        `${endPointAPI.PRODUCT.GET_DETAIL_GOLF_PRICE}/${id}`
      );
    },
    creattGolfPrice: async (payload: PricingManageInterface, golfPackageId: number) => {
      return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_GOLF_PRICE}/${golfPackageId}`, payload);
    },
    importGolfPrice: async ( payload: FormData, golfPackageId: number) => {
      return await httpMultipartInstance.post(`${endPointAPI.PRODUCT.IMPORT_GOLF_PRICE}/${golfPackageId}`, payload);
    },
    updattGolfPrice: async (payload: PricingEditRequestInteface, id: number) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_GOLF_PRICE}/${id}`,
        payload
      );
    },
    updateMultiGolfPrice: async (payload: UpdateMultiPricingRequest) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_GOLF_MULTI_PRICE}`,
        payload
      );
    },
    delettGolfPrice: async (payload: {ids: number[]}) => {
      return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_GOLF_PRICE}`, payload);
    },

  // categories
  getListCategoriesByType: async (type: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_CATEGORIES}/${type}`
    );
  },
  createCategory: async (payload: CategoryType) => {
    return await apiInstance.post(endPointAPI.PRODUCT.CREATE_CATEGORY, payload);
  },
  updateCategory: async (payload: {
    title: string,
    description: string
  }, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_CATEGORY}/${id}`,
      payload
    );
  },
  deleteCategory: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_CATEGORY}/${id}`);
  },
};

export default productService;

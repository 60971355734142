import React, { useEffect, useState } from 'react'
import DrawerCustom from '../../../components/drawer'
import { Box, Button, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import taskService from '../../../services/taskService';
import { SearchTaskBarPagination, TaskResponseList, UserItem } from '../../../interfaces/kanban';
import ViewTask from './ViewTask';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import SearchBar from './SearchBar';
import SearchBarPagination from './SearchBarPagination';
import userService from '../../../services/userService';
import { userAuth } from '../../../interfaces/authentication';

interface Props {
    handleClose: () => void;
    isOpen: boolean;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CompleteTable = ({ handleClose, isOpen }: Props) => {
    const user: userAuth = JSON.parse(localStorage.getItem("user") as string);
    const [dataList, setDataList] = useState<TaskResponseList>();
    const [viewTask, setViewTask] = useState(false);
    const [idView, setIdView] = useState(0);
    const [agentList, setAgentList] = useState<{ label: string; value: number }[]>([]);
    const [loadingData, setloadingData] = useState(false);

    const [dataSearch, setDataSearch] = useState<SearchTaskBarPagination>({
        keyWord: "",
        status: "COMPLETE",
        priority: "",
        deadlineFrom: "",
        deadlineTo: "",
        idUser: user.id.toString(),
        pageSize: 10,
        pageNumber: 0
    });

    const fetchData = async () => {
        if (isOpen) {
            setloadingData(true);
            Swal.fire({
                title: "Fetching Data...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading();
                },
            });

            let [response, responseListUser] = await Promise.all([
                taskService.getTaskFinish(dataSearch),
                userService.getSimple()
            ]);

            // let response = await taskService.getTaskFinish(dataSearch);
            if (response.status === 200) {
                Swal.close();
                let listData: TaskResponseList = response.data;
                if (listData) {
                    setDataList(listData);
                }
            }
            if (responseListUser.status === 200) {
                setAgentList(responseListUser.data.map((item: UserItem) => ({
                    label: item.firstName + ' ' + item.lastName,
                    value: item.id
                })));
            }
            setloadingData(false);
        }
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        const dataa = { ...dataSearch, pageNumber: newPage }
        setDataSearch(dataa);
    };

    useEffect(() => {
        fetchData();
    }, [dataSearch]);

    useEffect(() => {
        if (isOpen) {
            fetchData();
        }
    }, [isOpen])

    return (
        <DrawerCustom title={"Task Complete"} handleClose={handleClose} openDrawer={isOpen}>
            <DialogContent sx={{ background: "#efecec", px: 2 }}>
                <Paper sx={{ p: 2, pt: 3, mt: 2, boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)", borderRadius: 3 }}>
                    <SearchBarPagination status='COMPLETE' setDataSearch={setDataSearch} dataSearch={dataSearch} loading={loadingData} agentList={agentList} />
                    { dataList && dataList.total > 0 ?
                        <React.Fragment>
                            <TablePagination
                                component="div"
                                count={dataList.total}
                                page={dataSearch.pageNumber}
                                onPageChange={handleChangePage}
                                rowsPerPage={dataSearch.pageSize}
                                onRowsPerPageChange={(e) => {
                                    const dataa = { ...dataSearch, pageSize: Number(e.target.value) }
                                    setDataSearch(dataa);
                                }}
                                rowsPerPageOptions={[10, 50, 100, 150]}
                            />
                            <TableContainer component={Box}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600 }} align={"center"}>#</TableCell>
                                            <TableCell sx={{ fontWeight: 600 }}>Task Name</TableCell>
                                            <TableCell sx={{ fontWeight: 600 }}>Deadline</TableCell>
                                            <TableCell sx={{ fontWeight: 600 }}>Responsible person</TableCell>
                                            <TableCell sx={{ fontWeight: 600 }}>Responsible person extra</TableCell>
                                            <TableCell align={"center"}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataList.data.map((item, index) => <StyledTableRow key={index}>
                                            <TableCell sx={{ py: 1, width: "5%" }} align={"center"}>{dataSearch.pageSize * (dataSearch.pageNumber) + index + 1}</TableCell>
                                            <TableCell sx={{ py: 1, width: "35%" }} >{item.title}</TableCell>
                                            <TableCell sx={{ py: 1, width: "15%" }} >{item.deadline ? moment(item.deadline).format('ddd, DD-MMM-YYYY') : "No Deadline"}</TableCell>
                                            <TableCell sx={{ py: 1 }} >{item.responsiblePersonInfo.firstName} {item.responsiblePersonInfo.lastName}</TableCell>
                                            <TableCell sx={{ py: 1 }}>
                                                {item.responsiblePersonExtraInfo.map((i, index) =>
                                                    <span style={{ fontWeight: 600 }} key={index}> {index == 0 ? "" : "|"} {i.firstName} {i.lastName}</span>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ py: 1, width: "5%" }} align={"center"}>
                                                <Button onClick={() => {
                                                    setIdView(item.id);
                                                    setViewTask(true);
                                                }} size={"small"} variant={"contained"}>View</Button>
                                            </TableCell>
                                        </StyledTableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    : <Box display={'flex'} justifyContent={'center'}>
                        <Typography variant='h6' my={3}>No records found !!</Typography>
                        </Box>}
                    <ViewTask handleClose={() => setViewTask(false)} isViewTask={viewTask} idDetail={idView} callBackList={() => null} />
                </Paper>
            </DialogContent>
        </DrawerCustom>
    )
}

export default CompleteTable